/** @format */

import {Card, CardContent, useTheme} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import useScheduleMutations from "../../graphql/useScheduleMutations";
import {useAllSchedulesForPartition} from "../../graphql/useScheduleQueries";
import {useRealmApp} from "../../RealmApp";
import SidebarPageBox from "../Sidebar/SidebarPageBox";
import Loading from "../UI/Loading";
import MenuWithSubmit from "../UI/MenuWithSubmit";
import AddMaintenanceSchedule from "./AddMaintenanceSchedule/AddMaintenanceScheduleForm";
import MaintenanceSchedulesList from "./MaintenanceSchedulesList";
import SelectedMaintenanceSchedule from "./SelectedMaintenanceSchedule";
import UpdateOdometer from "./UpdateOdometer";
import {calculateDisplayedDistance} from "./utils";

function SelectedMotorcycle(props) {
  const app = useRealmApp();
  const theme = useTheme();
  const {allMaintenanceSchedules, loading, refetch} =
    useAllSchedulesForPartition(app.currentUser);
  const {
    addMaintenanceSchedule,
    updateMaintenanceSchedule,
    deleteMaintenanceSchedule,
  } = useScheduleMutations(app.currentUser);

  // const maintenanceSchedulesContext = useContext(MaintenanceSchedulesContext);
  const motorcycle = props.motorcycle;
  const maintenanceSchedules = allMaintenanceSchedules.filter((item) => {
    return item.motorcycleId === motorcycle.id;
  });

  console.log(`maintenanceSchedules: ${maintenanceSchedules}`);
  console.log(`selected motorcycle Id: ${motorcycle.id}`);
  const scheduleIds = allMaintenanceSchedules.map(
    (schedule) => schedule.motorcycleId
  );
  console.log(`All motorcycleId's for all schedules: ${scheduleIds}`);
  const [
    selectedMaintenanceScheduleIndex,
    setSelectedMaintenanceScheduleIndex,
  ] = useState(null);

  function selectMaintenanceScheduleHandler(maintenanceSchedule) {
    const index = maintenanceSchedules.findIndex((item) => {
      return item.id === maintenanceSchedule.id;
    });
    setSelectedMaintenanceScheduleIndex(index);
  }

  function addMaintenanceScheduleHandler(maintenanceSchedule) {
    // maintenanceSchedulesContext.addMaintenanceSchedule(maintenanceSchedule);
    addMaintenanceSchedule(maintenanceSchedule);
  }

  function deleteMaintenanceScheduleHandler(maintenanceScheduleId) {
    // maintenanceSchedulesContext.deleteMaintenanceSchedule(
    //   maintenanceScheduleId
    // );
    deleteMaintenanceSchedule(maintenanceScheduleId);
    setSelectedMaintenanceScheduleIndex(null);
  }

  if (motorcycle == null) {
    alert("No valid motorcycle was passed to Motorcycle component.");
  }

  const userDistanceUnit = localStorage.getItem("distanceUnit");

  useEffect(() => {
    setSelectedMaintenanceScheduleIndex(null);
    // refetch();
  }, [motorcycle.id]);

  return (
    <SidebarPageBox>
      <AppBar
        elevation={0}
        position="sticky"
        sx={{
          width: "100%",
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" noWrap component="div">
            {`${motorcycle.brand} ${motorcycle.model} ${motorcycle.year}`}
          </Typography>
          <Box
            display="flex"
            flexDirection={"row"}
            sx={{alignItems: "center"}}
          >
            <Card sx={{maxHeight: 50, borderRadius: 5}}>
              <CardContent sx={{pt: 0, textAlign: "center"}}>
                <Typography variant="h6" sx={{mb: -1.5}}>
                  {calculateDisplayedDistance(
                    motorcycle.odometer,
                    userDistanceUnit,
                    motorcycle.distanceUnit || "km"
                  )}
                </Typography>
                <Typography variant="p">
                  {localStorage.getItem("distanceUnit")}
                </Typography>
              </CardContent>
            </Card>
            <UpdateOdometer motorcycle={motorcycle}></UpdateOdometer>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          position: "sticky",
          flexGrow: 1,
          minHeight: 0, // https://stackoverflow.com/questions/36230944/prevent-flex-items-from-overflowing-a-container
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <AppBar
            elevation={0}
            sx={{ml: 0, mt: 0, position: "sticky"}}
            color="tertiary"
          >
            <Toolbar
              variant="dense"
              sx={{
                borderTop: 1,
                borderBottom: 1,
                borderColor: theme.palette.secondary.main,
                justifyContent: "space-between",
              }}
            >
              <MenuWithSubmit
                childrenProps={{motorcycle: motorcycle}}
                onSubmit={addMaintenanceScheduleHandler}
                label={"Add Reminder"}
              >
                <AddMaintenanceSchedule></AddMaintenanceSchedule>
              </MenuWithSubmit>
            </Toolbar>
          </AppBar>
          {loading ? (
            <Loading></Loading>
          ) : (
            <MaintenanceSchedulesList
              motorcycle={motorcycle}
              maintenanceSchedules={maintenanceSchedules.filter(
                (maintenanceSchedule) => {
                  return maintenanceSchedule.motorcycleId === motorcycle.id;
                }
              )}
              onClick={selectMaintenanceScheduleHandler}
              selectedMaintenanceSchedule={
                maintenanceSchedules[selectedMaintenanceScheduleIndex]
              }
            ></MaintenanceSchedulesList>
          )}
        </Box>
        {selectedMaintenanceScheduleIndex !== null ? (
          <SelectedMaintenanceSchedule
            maintenanceSchedule={
              maintenanceSchedules[selectedMaintenanceScheduleIndex]
            }
            motorcycle={motorcycle}
            onDelete={deleteMaintenanceScheduleHandler}
            onEdit={updateMaintenanceSchedule}
            onUpdate={updateMaintenanceSchedule}
          ></SelectedMaintenanceSchedule>
        ) : null}
      </Box>
    </SidebarPageBox>
  );
}

export default SelectedMotorcycle;

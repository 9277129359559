/** @format */

function getTaskWithHighestOdometer(finishedTasks, userDistanceUnit) {
  // we have to convert all the tasks to the same unit first.

  return finishedTasks.reduce(
    (prevTask, currentTask) =>
      calculateDisplayedDistance(
        prevTask.odometer,
        userDistanceUnit,
        prevTask.distanceUnit
      ) >
      calculateDisplayedDistance(
        currentTask.odometer,
        userDistanceUnit,
        currentTask.distanceUnit
      )
        ? prevTask
        : currentTask,
    0
  );
}

export function getMotorcycleName(motorcycle) {
  return `${motorcycle.brand} ${motorcycle.model} ${motorcycle.year}`;
}

export function dateToISOString(date) {
  return date.toISOString().split("T")[0];
}

export function calculateDisplayedDistance(
  distanceValue,
  userDistanceUnit,
  distanceUnit
) {
  // Display a period with value distanceValue and distanceUnit in terms of userDistanceUnit
  if (userDistanceUnit === distanceUnit) {
    return +distanceValue;
  }
  if (userDistanceUnit === "km" && distanceUnit === "mi") {
    return +(+distanceValue * 1.60934).toFixed(2);
  }
  if (userDistanceUnit === "mi" && distanceUnit === "km") {
    return +(+distanceValue / 1.60934).toFixed(2);
  }
}

export default getTaskWithHighestOdometer;
const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export function dateDiffInDays(a, b) {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

function addDatePeriod(date, datePeriodValue, datePeriodUnit) {
  if (datePeriodUnit === "days") {
    date = new Date(date.setDate(date.getDate() + datePeriodValue));
  }
  if (datePeriodUnit === "weeks") {
    date = new Date(date.setDate(date.getDate() + datePeriodValue * 7));
  }
  if (datePeriodUnit === "months") {
    date = new Date(date.setMonth(date.getMonth() + datePeriodValue));
  }
  if (datePeriodUnit === "years") {
    date = new Date(date.setFullYear(date.getFullYear() + datePeriodValue));
  }

  return date;
}

export function calculateDistanceToGo(
  task,
  schedule,
  motorcycle,
  userDistanceUnit
) {
  const taskOdometer = calculateDisplayedDistance(
    task.odometer || 0,
    userDistanceUnit,
    task.distanceUnit || userDistanceUnit
  );

  const motorcycleOdometer = calculateDisplayedDistance(
    motorcycle.odometer,
    userDistanceUnit,
    motorcycle.distanceUnit || userDistanceUnit
  );

  const schedulePeriod = calculateDisplayedDistance(
    schedule.distancePeriodValue,
    userDistanceUnit,
    schedule.distancePeriodUnit
  );
  let distanceToGo = null;
  if (schedule.distancePeriodValue) {
    distanceToGo = taskOdometer + schedulePeriod - motorcycleOdometer;
  }
  return distanceToGo;
}

export function calculateDaysToGo(
  maintenanceSchedule,
  taskWithHighestOdometer
) {
  if (maintenanceSchedule.datePeriodValue && maintenanceSchedule.startingDate) {
    const latestDate =
      taskWithHighestOdometer.date || maintenanceSchedule.startingDate || null;
    let nextMaintenanceDate = new Date(latestDate);
    nextMaintenanceDate = addDatePeriod(
      nextMaintenanceDate,
      maintenanceSchedule.datePeriodValue,
      maintenanceSchedule.datePeriodUnit
    );

    return dateDiffInDays(new Date(), nextMaintenanceDate);
  } else {
    return null;
  }
}

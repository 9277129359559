/** @format */

import { FormHelperText, MenuItem, Select, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { forwardRef, Fragment } from "react";

const InputField = forwardRef((props, ref) => {
  const {
    label,
    type,
    name,
    handleChange,
    handleBlur,
    errorMessage,
    isValid,
    value,
    selectValues,
    inputProps,
    sx,
    unitValue,
    handleUnitChange,
    unitSelectValues,
  } = props;

  let unitSelectMenuItems = null;
  if (unitSelectValues && unitSelectValues.length > 0) {
    const unitMenuItems = unitSelectValues.map((value, index) => (
      <MenuItem value={value} key={index}>
        {value}
      </MenuItem>
    ));
    unitSelectMenuItems = (
      <Select
        value={unitValue}
        size="small"
        onChange={handleUnitChange}
        name={name}
      >
        {unitMenuItems}
      </Select>
    );
  }

  let mainField = null;
  if (type === "select") {
    const menuItems = selectValues.map((value, index) => (
      <MenuItem value={value} key={index}>
        {value}
      </MenuItem>
    ));
    mainField = (
      <Select
        value={value}
        size="small"
        onChange={handleChange}
        name={name}
        sx={{ ...sx, flexGrow: 1 }}
      >
        {menuItems}
      </Select>
    );
  } else {
    mainField = (
      <TextField
        error={isValid === false}
        name={name}
        label={label}
        size="small"
        type={type}
        InputLabelProps={{ shrink: true }}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        inputProps={inputProps}
        inputRef={ref}
        sx={{ ...sx, flexGrow: 1 }}
        FormHelperTextProps={{ border: 1, color: "red" }}
      ></TextField>
    );
  }

  const component = (
    <Fragment key={name}>
      <Box sx={{ display: "flex", gap: 1 }}>
        {mainField}
        {unitSelectMenuItems}
      </Box>
      <FormHelperText error={true} sx={{ px: 1 }}>
        {isValid === false ? errorMessage : " "}
      </FormHelperText>
    </Fragment>
  );
  return component;
});

export default InputField;

import React from "react";
import { useUserForPartition } from "../graphql/useUserQueries";
import { useSubscriptionForEmail } from "../graphql/useSubscriptionQueries";
import SubscriptionContext from "./SubscriptionContext";
import { useRealmApp } from "../RealmApp";
import { dateDiffInDays } from "../components/SelectedMotorcycle/utils";

const NUM_TRIAL_DAYS = 7;

function getNonStripeTrialEndDate(userData) {
  const creationDate = userData?.creationDate;
  if (creationDate) {
    const date = new Date(creationDate);
    const trialEnd = new Date(date.setDate(date.getDate() + NUM_TRIAL_DAYS));
    console.log(`trial end: ${trialEnd.toLocaleDateString()}`);
    return trialEnd;
  } else return null;
}

function getCombinedSubscriptionEndDate(
  nonStripeTrialEndDate,
  subscriptionEnd
) {
  if (subscriptionEnd === null || subscriptionEnd === undefined) {
    return null;
  }
  let subscriptionEndDate = new Date(subscriptionEnd);

  const nonStripeTrialDaysLeft = Math.max(
    dateDiffInDays(new Date(), new Date(nonStripeTrialEndDate)),
    0
  );
  return new Date(
    subscriptionEndDate.setDate(
      subscriptionEndDate.getDate() + nonStripeTrialDaysLeft
    )
  );
}

function getSubscriptionInfo(subscription, userData) {
  let {
    cancelAtPeriodEnd,
    id,
    customerId,
    email,
    status,
    subscriptionEnd,
    userId,
  } = subscription;
  const nonStripeTrialEndDate = getNonStripeTrialEndDate(userData);
  console.log(`subscription status: ${status}`);
  console.log(`subscriptionEnd: ${subscriptionEnd}`);
  console.log(`cancelAtPeriodEnd: ${cancelAtPeriodEnd}`);

  let combinedSubscriptionEnd = getCombinedSubscriptionEndDate(
    nonStripeTrialEndDate,
    subscriptionEnd
  );

  let active =
    ["active", "trialing", "past_due"].includes(status) ||
    new Date() < combinedSubscriptionEnd;
  let message = "";

  console.log(`active: ${active}`);
  console.log(`subscriptionEnd: ${subscriptionEnd}`);
  console.log(`combinedSubscriptionEnd: ${combinedSubscriptionEnd}`);

  if (cancelAtPeriodEnd && subscriptionEnd) {
    message = `Subscribed until ${new Date(
      combinedSubscriptionEnd
    ).toLocaleDateString()}`;
  } else if (combinedSubscriptionEnd && new Date() > combinedSubscriptionEnd) {
    message = `Subscription expired`;
  } else if (
    combinedSubscriptionEnd === null &&
    new Date() <= nonStripeTrialEndDate
  ) {
    message = `Trial until ${nonStripeTrialEndDate.toLocaleDateString()}`;
  } else if (active) {
    message = `Subscribed`;
  }

  return {
    active: active,
    message: message,
    subscriptionId: id,
    customerId: customerId,
    cancelAtPeriodEnd: cancelAtPeriodEnd,
    subscriptionEnd: subscriptionEnd,
  };
}

export const useSubscription = () => {
  const subscriptionContext = React.useContext(SubscriptionContext);
  if (!subscriptionContext) {
    throw new Error(
      `You must call useSubscription() inside of a <SubscriptionProvider />`
    );
  }
  return subscriptionContext.subscription;
};

export default function SubscriptionProvider(props) {
  const app = useRealmApp();
  const user = app.currentUser;
  const { userData, loading: userLoading } = useUserForPartition(user);
  const trialEndDate = getNonStripeTrialEndDate(userData);
  const { subscription, loading: subscriptionLoading } =
    useSubscriptionForEmail(user);

  let {
    active,
    message,
    subscriptionId,
    customerId,
    cancelAtPeriodEnd,
    subscriptionEnd,
  } = getSubscriptionInfo(subscription, userData);

  const context = {
    subscription: {
      subscriptionId: subscriptionId,
      customerId: customerId,
      cancelAtPeriodEnd: cancelAtPeriodEnd,
      message: message,
      active: active,
      trialEndDate: trialEndDate,
      loading: subscriptionLoading,
      subscriptionEnd: subscriptionEnd,
    },
  };

  return (
    <SubscriptionContext.Provider value={context}>
      {props.children}
    </SubscriptionContext.Provider>
  );
}

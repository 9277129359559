/** @format */

import {ListItemButton, ListItemText, useTheme} from "@mui/material";

export default function MotorcycleTemplateListItem(props) {
  const motorcycleTemplate = props.motorcycleTemplate;
  const creator = motorcycleTemplate.creator;
  const id = motorcycleTemplate.id;
  const brand = motorcycleTemplate.brand;
  const model = motorcycleTemplate.model;
  const year = motorcycleTemplate.year;
  const theme = useTheme();
  return (
    <ListItemButton
      onClick={props.onClick.bind(null, motorcycleTemplate)}
      selected={props.selected}
      sx={{
        "&.Mui-selected, &.Mui-selected:hover, &:hover": {
          backgroundColor: theme.palette.tertiary.main,
        },
        display: "flex",
        justifyContent: "space-between",

      }}
    >
      <ListItemText
        primary={`${model} ${year}`}
        primaryTypographyProps={{maxWidth: 300, noWrap: true}}
        secondary={`${brand}`}
        secondaryTypographyProps={{color: theme.palette.primary.contrastText, maxWidth: 300, noWrap: true}}
      ></ListItemText>
      <ListItemText
        secondary={`created by ${creator}`}
        primaryTypographyProps={{textAlign: "right"}}
        sx={{flexGrow: 0}}
        secondaryTypographyProps={{
          color: theme.palette.primary.contrastText, maxWidth: 300, noWrap: true, textAlign: "right",
        }}
      ></ListItemText>
    </ListItemButton>
  );
}

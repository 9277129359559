import { MenuItem } from "@mui/material";

export default function getMenuItems(items) {
  return items.map((item) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    );
  });
}

/** @format */

import { dateToISOString } from "../components/SelectedMotorcycle/utils";
import moment from "moment";

export function getDefaultDate() {
  const today = new Date();
  return dateToISOString(today);
}

function dateChecker(dateString1, dateString2) {
  return moment(dateString1, "YYYY-MM-DD") <= moment(dateString2, "YYYY-MM-DD");
}

export function nonemptyStringIsValid(string) {
  return string.trim() !== "";
}

export function dateIsValid(string) {
  return dateChecker(string, getDefaultDate());
}

export function positiveNumberIsValid(string) {
  return string.trim() !== "" && +string > 0;
}

export function nonNegativeNumberIsValid(string) {
  return string.trim() !== "" && +string >= 0;
}

export function nonemptyStringReducer(state, action) {
  if (action.type === "USER_INPUT") {
    return {
      value: action.value,
      isValid: nonemptyStringIsValid(action.value),
    };
  }
  if (action.type === "CLEAR") {
    return { value: "", isValid: null };
  }
  if (action.type === "VALIDATE") {
    return { value: state.value, isValid: nonemptyStringIsValid(state.value) };
  }
}

export function positiveNumberReducer(state, action) {
  if (action.type === "USER_INPUT") {
    return {
      value: action.value,
      isValid: positiveNumberIsValid(action.value),
    };
  }
  if (action.type === "CLEAR") {
    return { value: "", isValid: null };
  }
  if (action.type === "VALIDATE") {
    return {
      value: state.value,
      isValid: positiveNumberIsValid(state.value),
    };
  }
}

export function nonNegativeNumberReducer(state, action) {
  if (action.type === "USER_INPUT") {
    return {
      value: action.value,
      isValid: nonNegativeNumberIsValid(action.value),
    };
  }
  if (action.type === "CLEAR") {
    return { value: "", isValid: null };
  }
  if (action.type === "VALIDATE") {
    return {
      value: state.value,
      isValid: nonNegativeNumberIsValid(state.value),
    };
  }
}

export function dateReducer(state, action) {
  if (action.type === "USER_INPUT") {
    return {
      value: action.value,
      isValid: dateIsValid(action.value),
    };
  }
  if (action.type === "CLEAR") {
    return { value: "", isValid: null };
  }
  if (action.type === "VALIDATE") {
    return {
      value: state.value,
      isValid: dateIsValid(state.value),
    };
  }
}

/** @format */

import { TextField } from "@mui/material";
import { forwardRef } from "react";

const NameTextField = forwardRef((props, ref) => {
  const error = props.error;
  const value = props.value;
  const onChange = props.onChange;
  const onValidate = props.onValidate;
  const sx = props.sx;
  return (
    <TextField
      error={error}
      size="small"
      label={"Name"}
      value={value}
      onChange={onChange}
      onBlur={onValidate}
      inputRef={ref}
      sx={{ marginBottom: 2, ...sx }}
    ></TextField>
  );
});

export default NameTextField;

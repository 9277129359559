import {Controller, useForm} from "react-hook-form";
import {calculateDisplayedDistance, dateToISOString} from "./utils";
import {MenuItem, Select, TextField, useTheme} from "@mui/material";
import {getMenuItemProps, getSelectProps, getTextfieldProps,} from "../Styling/styling_utils";
import {Box} from "@mui/system";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {useState} from "react";
import Typography from "@mui/material/Typography";
import {isAfterToday} from "../Forms/validationFunctions";

export default function EditMaintenanceSchedule(props) {
  const userDistanceUnit = localStorage.getItem("distanceUnit");
  const theme = useTheme();
  const today = new Date();
  const defaultDate = dateToISOString(today);

  const [showBottomError, setShowBottomError] = useState(false);
  const maintenanceSchedule = props.maintenanceSchedule;
  const name = maintenanceSchedule.name;
  const datePeriod = maintenanceSchedule.datePeriodValue;
  const distancePeriod = maintenanceSchedule.distancePeriodValue;
  const datePeriodUnit = maintenanceSchedule.datePeriodUnit;
  const distancePeriodUnit = maintenanceSchedule.distancePeriodUnit;
  const startingDate = maintenanceSchedule.startingDate;

  function onSubmit(data) {
    console.log(data);
    setShowBottomError(false);
    if (data.useDatePeriod === false && data.useDistancePeriod === false) {
      setShowBottomError(true);
    } else {
      let newDistancePeriod = 0;
      let newDatePeriod = 0;
      let newStartingDate = null; // NOTE: this does not update the startingDate field!

      if (data.useDistancePeriod) {
        newDistancePeriod = +data.distancePeriod;
      }
      if (data.useDatePeriod) {
        newDatePeriod = +data.datePeriod;
        newStartingDate = new Date(data.startingDate);
      }
      let newMaintenanceSchedule = {
        name: data.name,
        datePeriodValue: newDatePeriod,
        distancePeriodValue: newDistancePeriod,
        datePeriodUnit: data.datePeriodUnit,
        distancePeriodUnit: data.distanceUnit,
        startingDate: newStartingDate,
      };

      if (newStartingDate === null) {
        newMaintenanceSchedule = {
          ...newMaintenanceSchedule,
          startingDate_unset: true,
        };
      }
      props.onEdit(newMaintenanceSchedule);
      props.onClose();
    }
  }

  const {handleSubmit, control, reset, register, errors, getValues, watch} =
    useForm({
      defaultValues: {
        name: name,
        distancePeriod: calculateDisplayedDistance(
          distancePeriod,
          userDistanceUnit,
          distancePeriodUnit
        ),
        datePeriod: datePeriod,
        distanceUnit: userDistanceUnit,
        datePeriodUnit: datePeriodUnit,
        startingDate: startingDate
          ? dateToISOString(new Date(startingDate))
          : "",
        useDistancePeriod: distancePeriod ? true : false,
        useDatePeriod: datePeriod ? true : false,
      },
    });
  return (
    <Box
      backgroundColor={theme.palette.tertiary.main}
      color={theme.palette.secondary.contrastText}
      sx={{
        borderRadius: 3,
        p: 2,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
        // mr: 2,
        gap: 2,
      }}
    >
      <Typography noWrap={true} sx={{maxWidth: "90%"}}>{`Editing ${maintenanceSchedule.name}`}</Typography>
      <Controller
        name="name"
        control={control}
        rules={{required: "required"}}
        render={({field: {onChange, value}, fieldState: {error}}) => (
          <TextField
            label={"name"}
            fullWidth={true}
            value={value}
            sx={{
              my: 1,
              ...getTextfieldProps(theme),
            }}
            onChange={onChange}
            error={!!error}
            {...register("name")}
            helperText={error ? error.message : null}
          ></TextField>
        )}
      />
      <Box
        sx={{
          border: 1,
          borderRadius: 2,
          p: 1,
          borderColor: theme.palette.secondary.main,
        }}
      >
        <Box sx={{display: "flex", flexDirection: "row", gap: 2}}>
          <Controller
            name="distancePeriod"
            control={control}
            rules={{
              validate: {
                test: (value) => {
                  if (!value && getValues("useDistancePeriod")) {
                    return "Required";
                  } else return true;
                },
              },
            }}
            render={({field: {onChange, value}, fieldState: {error}}) => (
              <TextField
                label={"Repeat every"}
                multiline
                fullWidth={true}
                value={value}
                size="small"
                sx={{
                  "& .MuiFormHelperText-root.Mui-error": {
                    position: "absolute",
                    top: "100%",
                  },
                  my: 1,
                  ...getTextfieldProps(theme),
                }}
                onChange={onChange}
                error={!!error}
                {...register("distancePeriod")}
                helperText={error ? error.message : null}
              ></TextField>
            )}
          />
          <Controller
            name="distanceUnit"
            control={control}
            render={({field: {onChange, value}, fieldState: {error}}) => (
              <Select
                label={""}
                type={"number"}
                // variant={"filled"}
                value={value}
                size="small"
                sx={{
                  my: 1,
                  ...getSelectProps(theme),
                }}
                onChange={onChange}
                MenuProps={{
                  sx: {
                    ...getMenuItemProps(theme),
                  },
                }}
                error={!!error}
                {...register("distanceUnit")}
              >
                <MenuItem value={"km"}>{"km"}</MenuItem>
                <MenuItem value={"mi"}>{"mi"}</MenuItem>
              </Select>
            )}
          />
        </Box>
        <Controller
          name="useDistancePeriod"
          control={control}
          render={({field: props, fieldState: {error}}) => (
            <FormControlLabel
              label={"Include"}
              control={
                <Checkbox
                  checked={props.value}
                  {...props}
                  style={{
                    color: theme.palette.primary.contrastText,
                  }}
                  onChange={(e) => {
                    props.onChange(e.target.checked);
                  }}
                  sx={{my: 1}}
                ></Checkbox>
              }
            ></FormControlLabel>
          )}
        />
      </Box>
      <Box
        sx={{
          border: 1,
          borderRadius: 2,
          p: 1,
          borderColor: theme.palette.secondary.main,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{display: "flex", flexDirection: "row", gap: 2}}>
          <Controller
            name="datePeriod"
            control={control}
            rules={{
              validate: {
                test: (value) => {
                  if (!value && getValues("useDatePeriod")) {
                    return "Required";
                  } else return true;
                },
              },
            }}
            render={({field: {onChange, value}, fieldState: {error}}) => (
              <TextField
                label={"Repeat every"}
                multiline
                fullWidth={true}
                value={value}
                size="small"
                sx={{
                  "& .MuiFormHelperText-root.Mui-error": {
                    position: "absolute",
                    top: "100%",
                  },
                  my: 1,
                  ...getTextfieldProps(theme),
                }}
                onChange={onChange}
                error={!!error}
                {...register("datePeriod")}
                helperText={error ? error.message : null}
              ></TextField>
            )}
          />
          <Controller
            name="datePeriodUnit"
            control={control}
            render={({field: {onChange, value}, fieldState: {error}}) => (
              <Select
                label={""}
                type={"number"}
                // variant={"filled"}
                value={value}
                size="small"
                sx={{
                  my: 1,
                  ...getSelectProps(theme),
                }}
                onChange={onChange}
                MenuProps={{
                  sx: {
                    ...getMenuItemProps(theme),
                  },
                }}
                error={!!error}
                {...register("datePeriodUnit")}
              >
                <MenuItem value={"days"}>days</MenuItem>
                <MenuItem value={"weeks"}>weeks</MenuItem>
                <MenuItem value={"months"}>months</MenuItem>
                <MenuItem value={"years"}>years</MenuItem>
              </Select>
            )}
          />
        </Box>
        <Controller
          name="startingDate"
          control={control}
          rules={{
            validate: {
              bla: (value) => {
                console.log(`date value: ${value}`);
                if (
                  !value &&
                  value.length === 0 &&
                  getValues("useDatePeriod")
                ) {
                  return "Required";
                } else return true;
              },
              inPast: (value) => {
                if (isAfterToday(value) && getValues("useDatePeriod")) {
                  return "Must be in the past.";
                } else return true;
              },
            },
          }}
          render={({field: {onChange, value}, fieldState: {error}}) => (
            <TextField
              label={"Starting date"}
              type={"date"}
              value={value}
              InputLabelProps={{shrink: true}}
              sx={{
                my: 2,
                ...getTextfieldProps(theme),
              }}
              onChange={onChange}
              inputProps={{max: defaultDate}}
              error={!!error}
              {...register("startingDate")}
              helperText={error ? error.message : null}
            ></TextField>
          )}
        />
        <Controller
          name="useDatePeriod"
          control={control}
          render={({field: props, fieldState: {error}}) => (
            <FormControlLabel
              label={"Include"}
              control={
                <Checkbox
                  checked={props.value}
                  {...props}
                  style={{
                    color: theme.palette.primary.contrastText,
                  }}
                  onChange={(e) => {
                    props.onChange(e.target.checked);
                  }}
                  sx={{my: 1}}
                ></Checkbox>
              }
            ></FormControlLabel>
          )}
        />
      </Box>
      {showBottomError && (
        <Typography color="red" sx={{color: theme.palette.red.main}}>
          Must include at least one
        </Typography>
      )}
      <Button
        variant={"contained"}
        onClick={handleSubmit(onSubmit)}
        sx={{my: 2}}
        color={"orange"}
      >
        Save
      </Button>
    </Box>
  );
}

/** @format */

import { useQuery, gql } from "@apollo/client";
import React, { useEffect } from "react";

export const GetAllMotorcycleTemplatesQuery = gql`
  query GetAllMotorcycleTemplatessForPartition($partition: String!) {
    motorcycleTemplates(
      query: { OR: [{ _partition: $partition }, { creator: "admin" }] }
    ) {
      _id
      id
      _partition
      brand
      year
      model
      creator
    }
  }
`;

export function useAllMotorcycleTemplatesForPartition(user) {
  const { data, loading, error } = useQuery(GetAllMotorcycleTemplatesQuery, {
    variables: { partition: `creator=${user.id}` },
  });
  useEffect(() => {}, []);

  if (error) {
    throw new Error(`Failed to fetch motorcycles: ${error.message}`);
  }

  const motorcycleTemplates = data?.motorcycleTemplates ?? [];
  return {
    motorcycleTemplates,
    loading,
  };
}

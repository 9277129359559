import { Box } from "@mui/material";
import Loading from "../UI/Loading";
import SubscribeButton from "./SubscribeButton";
import { useSubscription } from "../../store/SubscriptionProvider";

export default function SubscriptionInfo(props) {
  const user = props.user;
  const subscription = useSubscription();
  const {
    subscriptionId,
    customerId,
    cancelAtPeriodEnd,
    message,
    active,
    trialEndDate,
    loading: subscriptionLoading,
  } = subscription;
  console.log(`subscription: ${JSON.stringify(subscription)}`);

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        gap: 2,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box>{subscriptionLoading ? <Loading></Loading> : `${message}`}</Box>
      <SubscribeButton user={user} customerId={customerId}></SubscribeButton>
    </Box>
  );
}

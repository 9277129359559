import { useEffect, useState } from "react";
import SuccessDisplay from "./SuccessDisplay";
import Message from "./Message";

export function PostPaymentPage() {
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState("");

  useEffect(() => {
    // in this location, this never gets called because this component is closed after visiting the checkout form
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }
    if (query.get("canceled")) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }

    console.log(`query: ${query}`);
  }, [sessionId]);

  let content = null;
  if (success && sessionId !== "") {
    content = <SuccessDisplay sessionId={sessionId} />;
  } else {
    content = <Message message={message} />;
  }

  return content;
}

import useFormFieldObject, { getComponentValue } from "../../Forms/config";
import { minValue, requiredRule } from "../../Forms/validationRules";
import useForm from "../../Forms/useForm";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import useMotorcycleMutations from "../../../graphql/useMotorcycleMutations";

export default function AddNewMotorcycleForm(props) {
  const user = props.user;
  const { addMotorcycle } = useMotorcycleMutations(user);
  const addMotorcycleTemplateFormObject = {
    brand: {
      ...useFormFieldObject({
        components: [
          {
            label: "Brand",
            name: "brand",
            type: "text",
            validationRules: [requiredRule("name")],
          },
        ],
        boxSx: {
          p: 1,
        },
      }),
    },
    model: {
      ...useFormFieldObject({
        components: [
          {
            label: "Model",
            name: "model",
            type: "text",
            validationRules: [requiredRule("model")],
          },
        ],
        boxSx: {
          p: 1,
        },
      }),
    },
    year: {
      ...useFormFieldObject({
        components: [
          {
            label: "Year",
            name: "year",
            type: "number",
            validationRules: [requiredRule("year"), minValue("year", 0)],
          },
        ],
        boxSx: {
          p: 1,
        },
      }),
    },
    odometer: {
      ...useFormFieldObject({
        components: [
          {
            label: "Odometer",
            name: "odometer",
            type: "number",
            unitValues: ["km", "mi"],
            defaultUnitValue: "km",
            validationRules: [
              requiredRule("odometer"),
              minValue("odometer", 0),
            ],
          },
        ],
        boxSx: {
          p: 1,
        },
      }),
    },
  };

  const { form, renderFormInputs, isFormValid, validateForm } = useForm(
    addMotorcycleTemplateFormObject
  );
  const { isValid, ref } = isFormValid();

  function onSubmit() {
    validateForm();
    if (!isValid && ref) {
      ref.current.focus();
    } else if (isValid) {
      const motorcycle = {
        brand: getComponentValue(form.brand.componentsInfo, "brand"),
        model: getComponentValue(form.model.componentsInfo, "model"),
        year: +getComponentValue(form.year.componentsInfo, "year"),
        odometer: +getComponentValue(form.odometer.componentsInfo, "odometer"),
        distanceUnit: getComponentValue(
          form.odometer.componentsInfo,
          "odometer",
          "unitValue"
        ),
        id: Math.random().toString(36).substr(2, 9).toString(),
      };

      addMotorcycle(motorcycle);
      props.onClose();
      console.log(`Saving motorcycle: ${JSON.stringify(motorcycle)}`);
    } else {
      alert("Error: not saving motorcycle template.");
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ p: 2 }}>{renderFormInputs()}</Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" sx={{ m: 3 }} onClick={onSubmit}>
          Add
        </Button>
      </Box>
    </Box>
  );
}

/** @format */

import { Box, TextField, Select, MenuItem } from "@mui/material";
import { forwardRef } from "react";
const DistancePeriodTextField = forwardRef((props, ref) => {
  const error = props.error;
  const value = props.value;
  const unit = props.unit;
  const onValueChange = props.onValueChange;
  const onUnitChange = props.onUnitChange;
  const label = props.label;
  const onValidate = props.onValidate;

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <TextField
        error={error}
        inputRef={ref}
        size="small"
        type="number"
        inputProps={{ step: 100, min: 0 }}
        value={value}
        onChange={onValueChange}
        label={label}
        onBlur={onValidate}
        sx={{ flexGrow: 1 }}
      ></TextField>
      <Select value={unit} size="small" onChange={onUnitChange}>
        <MenuItem value={"km"}>km</MenuItem>
        <MenuItem value={"mi"}>mi</MenuItem>
      </Select>
    </Box>
  );
});

export default DistancePeriodTextField;

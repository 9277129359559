import { Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@emotion/react";
import { Box } from "@mui/system";
import { calculateDisplayedDistance, dateToISOString } from "./utils";

export default function MaintenanceScheduleInfoView(props) {
  const theme = useTheme();
  // user distance unit
  const userDistanceUnit = localStorage.getItem("distanceUnit");

  const maintenanceSchedule = props.maintenanceSchedule;
  const datePeriod = maintenanceSchedule.datePeriodValue;
  const distancePeriod = maintenanceSchedule.distancePeriodValue;
  const datePeriodUnit = maintenanceSchedule.datePeriodUnit;
  const distancePeriodUnit = maintenanceSchedule.distancePeriodUnit;
  const startingDate = maintenanceSchedule.startingDate;

  return (
    <Box>
      {distancePeriod ? (
        <Typography>{`Every ${calculateDisplayedDistance(
          distancePeriod,
          userDistanceUnit,
          distancePeriodUnit
        )} ${userDistanceUnit}`}</Typography>
      ) : (
        ""
      )}
      {datePeriod ? (
        <Typography>{`Every ${datePeriod} ${datePeriodUnit}`}</Typography>
      ) : (
        ""
      )}
      {datePeriod ? (
        <Typography>{`Starting from ${dateToISOString(
          new Date(startingDate)
        )}`}</Typography>
      ) : (
        ""
      )}
    </Box>
  );
}

/** @format */
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, MenuItem, Select, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import StripePaymentsForm from "../Payment/StripePaymentsForm";

function SettingsPage(props) {
  // const currentDistanceUnit = props.currentDistanceUnit || "km";
  const currentDistanceUnit = localStorage.getItem("distanceUnit") || "km";
  const [distanceUnit, setDistanceUnit] = useState(currentDistanceUnit);

  function handleChangeDistanceUnit(event) {
    setDistanceUnit(event.target.value);
  }

  function onSubmit() {
    localStorage.setItem("distanceUnit", distanceUnit);
    props.onUpdateUser(props.user, { distanceUnit: distanceUnit });
    props.onClose();
  }

  return (
    <Modal open={props.open}>
      <Box
        sx={{
          width: 400,
          // height: 400,
          top: "10%",
          left: "35%",
          position: "absolute",
          bgcolor: "background.paper",
          display: "flex",
          flexDirection: "column",
          borderRadius: 3,
          p: 2,
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography variant="h4" sx={{ flexGrow: 1 }}>
            Settings
          </Typography>
          <IconButton onClick={props.onClose} sx={{}}>
            <CloseIcon></CloseIcon>
          </IconButton>
        </Box>
        <Box sx={{ display: "flex", p: 2, justifyContent: "space-between" }}>
          <Typography
            variant={"body"}
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            Email
          </Typography>
          <TextField
            value={props.user._profile.data.email}
            variant={"outlined"}
            size={"small"}
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            disabled={true}
          ></TextField>
        </Box>
        <Box sx={{ p: 2, flexGrow: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="body"
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              Unit of distance
            </Typography>
            <Select
              value={distanceUnit}
              size="small"
              onChange={handleChangeDistanceUnit}
            >
              <MenuItem value={"km"}>km</MenuItem>
              <MenuItem value={"mi"}>mi</MenuItem>
            </Select>
          </Box>
        </Box>

        <StripePaymentsForm user={props.user}></StripePaymentsForm>

        <Box sx={{ display: "flex", p: 2 }}>
          <Button
            variant="contained"
            sx={{ marginLeft: "auto" }}
            onClick={onSubmit}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default SettingsPage;

/** @format */

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Button, Typography, useTheme } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { isAdmin } from "../permissions";
import ButtonWithModal from "../UI/ButtonWithModal";
import MotorcyclesList from "./MotorcyclesList";
import AddMotorcycleModal from "./AddMotorcycle/AddMotorcycleModal";
import StorageIcon from "@mui/icons-material/Storage";

function SidebarList(props) {
  const user = props.user;
  const theme = useTheme();
  const handleSelectSidebarItem = props.handleSelectSidebarItem;
  const handleSelectMotorcycleId = props.handleSelectMotorcycleId;
  const selectedItemsState = props.selectedItemsState;
  const motorcycles = props.motorcycles;

  return (
    <List>
      {isAdmin(user) ? (
        <ListItemButton
          alignItems={"center"}
          onClick={(event) =>
            handleSelectSidebarItem(event, "motorcycles_database")
          }
          selected={
            selectedItemsState.selectedSidebarItem === "motorcycles_database"
          }
          sx={{
            "&.Mui-selected, &.Mui-selected:hover, &:hover": {
              backgroundColor: theme.palette.tertiary.main,
            },
            m: 2,
            borderRadius: 3,
          }}
        >
          <StorageIcon sx={{ pr: 1 }}></StorageIcon>
          <ListItemText primary="Motorcycles Database"></ListItemText>
        </ListItemButton>
      ) : null}
      <MotorcyclesList
        items={motorcycles}
        selectedItemId={selectedItemsState.selectedMotorcycleId}
        listItemButtonSx={{
          pl: 0,
          m: 2,
          borderRadius: 3,
          "&.Mui-selected, &.Mui-selected:hover, &:hover": {
            backgroundColor: theme.palette.tertiary.main,
          },
        }}
        onClick={handleSelectMotorcycleId}
        loading={props.loadingMotorcycles}
      ></MotorcyclesList>
      <ButtonWithModal
        button={
          <Button variant="contained" color="orange" sx={{ mx: 3, my: 2 }}>
            <AddCircleOutlineIcon></AddCircleOutlineIcon>
            <Typography variant="p" noWrap component="div">
              Add Motorcycle
            </Typography>
          </Button>
        }
        sx={{ p: 0, borderRadius: 2 }}
        disableBackdropClick={true}
      >
        <AddMotorcycleModal></AddMotorcycleModal>
      </ButtonWithModal>
    </List>
  );
}

export default SidebarList;

/** @format */

import { Box } from "@mui/system";
import { useCallback, useEffect, useState } from "react";

function useForm(formObj, validateInitially = false) {
  const [form, setForm] = useState(formObj);

  function renderFormInputs() {
    return Object.values(form).map((formFieldObject, index2) => {
      const { renderInputs, componentsInfo, sx } = formFieldObject;
      return (
        <Box
          key={index2}
          sx={{ display: "flex", flexDirection: "column", ...sx }}
        >
          {componentsInfo.map((componentInfo, index) => {
            const { name, value, valid, errorMessage, unitValue } =
              componentInfo;
            return renderInputs[index](
              onInputChange,
              onBlur,
              value,
              valid,
              errorMessage,
              name,
              unitValue,
              onUnitInputChange
            );
          })}
        </Box>
      );
    });
  }

  const getComponentInfoByName = useCallback(
    (name) => {
      const componentsKey = Object.keys(form).find((key) => {
        return form[key].componentsInfo.find((componentInfo) => {
          return componentInfo.name === name;
        });
      });
      const componentsInfo = [...form[componentsKey].componentsInfo.slice()];
      const componentIndex = componentsInfo.findIndex(
        (item) => item.name === name
      );

      const componentInfo = { ...componentsInfo[componentIndex] };
      return { componentInfo, componentIndex, componentsInfo, componentsKey };
    },
    [form]
  );

  const isInputFieldValid = useCallback((inputField, componentsInfo) => {
    // TODO: place this somewhere else, such that it doesn't compute it every single time?
    const optionalValue = componentsInfo.find((componentInfo) =>
      componentInfo.name.startsWith("optional")
    )?.value;

    for (const rule of inputField.validationRules) {
      if (
        !rule.validate(inputField.value, componentsInfo) &&
        (optionalValue === true || optionalValue == null)
      ) {
        inputField.errorMessage = rule.message;
        return false;
      }
    }

    return true;
  }, []);

  const setInputObjValidity = useCallback(
    (inputObj, componentsInfo) => {
      // update input field's validity
      const isValidInput = isInputFieldValid(inputObj, componentsInfo);
      inputObj.valid = isValidInput;
      // mark input field as touched
      inputObj.touched = true;
    },
    [isInputFieldValid]
  );

  const onBlur = useCallback(
    (event) => {
      const { name } = event.target;
      // const { inputObj, inputObjKey } = getComponentInfoByName(name);
      const { componentInfo, componentIndex, componentsInfo, componentsKey } =
        getComponentInfoByName(name);

      setInputObjValidity(componentInfo, componentsInfo);
      componentsInfo[componentIndex] = componentInfo;
      setForm({
        ...form,
        [componentsKey]: {
          ...form[componentsKey],
          componentsInfo: componentsInfo,
        },
      });
    },
    [form, getComponentInfoByName, setInputObjValidity]
  );

  const onInputChange = useCallback(
    (event) => {
      // not yet implemented
      const name = event.target.name;
      const value = name.startsWith("optional")
        ? event.target.checked
        : event.target.value;
      
      const { componentInfo, componentIndex, componentsInfo, componentsKey } =
        getComponentInfoByName(name);

      componentInfo.value = value;

      setInputObjValidity(componentInfo, componentsInfo);
      componentsInfo[componentIndex] = componentInfo;

      if (name.startsWith("optional")) {
        // In this case, we want to re-validate all the components within this componentsInfo
        for (let i = 0; i < componentsInfo.length; i++) {
          const tempComponentInfo = { ...componentsInfo[i] };
          setInputObjValidity(tempComponentInfo, componentsInfo);
          componentsInfo[i] = tempComponentInfo;
        }
      }

      setForm({
        ...form,
        [componentsKey]: {
          ...form[componentsKey],
          componentsInfo: componentsInfo,
        },
      });
    },
    [form, getComponentInfoByName, setInputObjValidity]
  );

  const onUnitInputChange = useCallback(
    (event) => {
      // not yet implemented
      const { name, value } = event.target;
      const inputObjKey = Object.keys(form).find((key) => {
        return form[key].componentsInfo.find((componentInfo) => {
          return componentInfo.name === name;
        });
      });

      const componentsInfo = [...form[inputObjKey].componentsInfo.slice()];
      const componentIndex = componentsInfo.findIndex(
        (item) => item.name === name
      );

      const inputObj = { ...componentsInfo[componentIndex] };
      inputObj.unitValue = value;
      componentsInfo[componentIndex] = inputObj;

      setForm({
        ...form,
        [inputObjKey]: { ...form[inputObjKey], componentsInfo: componentsInfo },
      });
    },
    [form]
  );

  const validateForm = useCallback(() => {
    const keys = Object.keys(form);
    let newForm = {};
    for (const key of keys) {
      const componentsInfo = form[key].componentsInfo;
      let newCompponentsInfo = [...componentsInfo];
      for (let i = 0; i < componentsInfo.length; i++) {
        const inputObj = componentsInfo[i];
        setInputObjValidity(inputObj, componentsInfo);
        newCompponentsInfo[i] = inputObj;
      }
      newForm = {
        ...newForm,
        [key]: { ...form[key], componentsInfo: newCompponentsInfo },
      };
    }
    setForm((prevForm) => {
      return { ...prevForm, ...newForm };
    });
  }, [form, setInputObjValidity]);

  const isFormValid = useCallback(() => {
    // form is valid if all components are valid
    // return ref of invalid component incase it is not valid
    let isValid = true;
    let ref = null;
    const arr = Object.values(form);

    loop1: for (let i = 0; i < arr.length; i++) {
      const componentsInfo = arr[i].componentsInfo;
      for (let j = 0; j < componentsInfo.length; j++) {
        const inputObj = { ...componentsInfo[j] };
        if (!inputObj.valid) {
          isValid = false;
          ref = inputObj.ref;
          break loop1;
        }
      }
    }
    return { isValid, ref };
  }, [form]);

  useEffect(() => {
    if (validateInitially) {
      validateForm();
    }
  }, [validateInitially]);

  return { form, renderFormInputs, isFormValid, validateForm };
}

export default useForm;

/** @format */

import { useCallback, useReducer, useState } from "react";
import {
  dateIsValid,
  dateReducer,
  nonemptyStringIsValid,
  nonemptyStringReducer,
  nonNegativeNumberIsValid,
  nonNegativeNumberReducer,
  positiveNumberIsValid,
  positiveNumberReducer,
} from "./reducers";

export default function useFormFields(type, initialValue = "") {
  let reducer = null;
  let validator = null;
  if (type === "nonemptyString") {
    reducer = nonemptyStringReducer;
    validator = nonemptyStringIsValid;
  } else if (type === "date") {
    reducer = dateReducer;
    validator = dateIsValid;
  } else if (type === "nonNegativeNumber") {
    reducer = nonNegativeNumberReducer;
    validator = nonNegativeNumberIsValid;
  } else if (type === "positiveNumber") {
    reducer = positiveNumberReducer;
    validator = positiveNumberIsValid;
  } else {
    throw Error(
      `Error! 'type' argument should be in ['date', 'nonemptyString', 'nonNegativeNumber', 'positiveNumber']. Got ${type}`
    );
  }
  const [state, dispatchValue] = useReducer(reducer, {
    value: initialValue,
    isValid: null,
  });

  function handleChange(event) {
    dispatchValue({ type: "USER_INPUT", value: event.target.value });
  }
  function validate() {
    dispatchValue({ type: "VALIDATE" });
  }

  const setState = useCallback((value) => {
    dispatchValue({ type: "USER_INPUT", value: value.toString() });
  }, []);

  return [state, handleChange, validate, setState];
}

import Button from "@mui/material/Button";

const payment_backend_url = process.env["REACT_APP_payment_backend_url"];
const price_id = process.env["REACT_APP_stripe_price_id"];

export default function SubscribeButton(props) {
  console.log(`backend_url: ${payment_backend_url}`);
  const user = props.user;
  const customerId = props.customerId;
  let button = null;
  let buttonContent = "";
  let action = null;
  let customerIdComponent = null;

  if (customerId) {
    action = payment_backend_url + "/create-portal-session";
    buttonContent = "View";
    customerIdComponent = (
      <input
        type="hidden"
        id="customer-id"
        name="customer_id"
        value={customerId}
      />
    );
  } else {
    action = payment_backend_url + "/create-checkout-session";
    buttonContent = "Subscribe";
  }

  button = (
    <form action={action} method="POST">
      <input
        type="hidden"
        id="customer-email"
        name="customer_email"
        value={user._profile.data.email}
      />
      <input
        type="hidden"
        id={"lookup-key"}
        name={"lookup_key"}
        value={`${price_id}`}
      ></input>
      {customerIdComponent}

      <Button variant={"contained"} type={"submit"} color={"orange"}>
        {buttonContent}
      </Button>
    </form>
  );
  return button;
}

/** @format */

import * as React from "react";
import {IconButton} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ButtonWithDeleteAlert(props) {
  const [open, setOpen] = React.useState(false);

  // options: 'normal', 'icon'
  const type = props.type !== undefined ? props.type : "normal";
  const title = props.title;
  const content = props.content;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {type === "normal" ? (
        <Button
          variant={props.variant}
          color={props.color}
          sx={{...props.sx}}
          onClick={handleClickOpen}
        >
          {props.children}
        </Button>
      ) : (
        <IconButton
          variant={props.variant}
          color={props.color}
          sx={{...props.sx}}
          onClick={handleClickOpen}
        >
          {props.children}
        </IconButton>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={"contained"} color={"secondary"} onClick={handleClose}>Cancel</Button>
          <Button
            variant={"contained"}
            onClick={() => {
              handleClose();
              props.onDelete();
            }}
            color={"red"}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

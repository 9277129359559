import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";

export default function Message({ message }) {
  return (
    <Box>
      <Typography>{message}</Typography>
      <Button href="/">Home</Button>
    </Box>
  );
}

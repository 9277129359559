/** @format */

import { Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";

export default function MotorcycleTemplateView(props) {
  const template = props.template;
  const theme = useTheme();
  let content = null;
  if (template) {
    content = (
      <Box
        sx={{
          backgroundColor: theme.palette.tertiary.main,
          p: 2,
          m: 1,
          borderRadius: 3,
        }}
      >
        <Typography>{template.brand}</Typography>
        <Typography>{template.model}</Typography>
        <Typography>{template.year}</Typography>
      </Box>
    );
  }
  return content;
}

/** @format */
import {List} from "@mui/material";
import {useEffect, useState} from "react";
import FinishedTaskItem from "./FinishedTaskItem";

function FinishedTasks(props) {
  const [selectedListItemId, setSelectedListItem] = useState(null);

  function selectListItemHandler(id) {
    setSelectedListItem(id);
  }

  const finishedTasks = props.finishedTasks;
  const maintenanceSchedule = props.maintenanceSchedule;

  useEffect(() => {
    setSelectedListItem(null);
  }, [maintenanceSchedule]);

  const finishedTasksItems = finishedTasks.map((finishedTask) => {
    return (
      <FinishedTaskItem
        finishedTask={finishedTask}
        selected={finishedTask.id === selectedListItemId}
        onClick={selectListItemHandler.bind(null, finishedTask.id)}
        onDelete={props.onDelete}
        maintenanceSchedule={maintenanceSchedule}
        onUpdate={props.onUpdate}
        key={finishedTask.id}
      ></FinishedTaskItem>
    );
  });
  return (
    <List sx={{gap: 1, display: "flex", flexDirection: "column"}}>
      {finishedTasksItems}
    </List>
  );
}

export default FinishedTasks;

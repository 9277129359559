/** @format */

export function isAdmin(user) {
  if (!user?.customData?.role) {
    return false;
  }

  if (user.customData.role === "admin") {
    return true;
  }
  return false;
}

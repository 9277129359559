import { createContext } from "react";

const SubscriptionContext = createContext({
  subscription: {
    subscriptionId: null,
    customerId: null,
    cancelAtPeriodEnd: false,
    message: "",
    active: false,
    trialEndDate: null,
    loading: false,
  },
});

export default SubscriptionContext;

/** @format */
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

function SidebarPageBox(props) {
  const theme = useTheme();
  return (
    <Box
      component="main"
      sx={{
        bgcolor: theme.palette.quaternary.main,
        color: theme.palette.quaternary.contrastText,
        p: 0,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {props.children}
    </Box>
  );
}

export default SidebarPageBox;

/** @format */

import {List} from "@mui/material";
import MotorcycleTemplate from "./MotorcycleTemplateListItem";

export default function MotorcycleTemplatesList(props) {
  const motorcycleTemplates = props.motorcycleTemplates;

  const motorcycleTemplatesItems = motorcycleTemplates.map(
    (template, index) => {
      return (
        <MotorcycleTemplate
          key={index}
          motorcycleTemplate={template}
          onClick={props.onClick}
          selected={props.selectedIndex === index}
        ></MotorcycleTemplate>
      );
    }
  );

  return (
    <List sx={{
      maxHeight: "calc(100% - 50px)", // MAGIC NUMBER
      p: 0,
      // height: 500,
      // border: 5,
      borderColor: "pink",
      boxSizing: "border-box",
      overflowY: "scroll",

      "&::-webkit-scrollbar": {
        width: 5,
        height: 30,
      },
      "&::-webkit-scrollbar-track": {
        // boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#3a3a3c",
        // outline: `1px solid slategrey`,
      },
    }}>
      {motorcycleTemplatesItems}
    </List>
  );
}

export function getTextfieldProps(theme) {
  // https://stackoverflow.com/questions/69836609/material-ui-how-to-change-datepicker-text-and-calendar-icon-color
  return {
    input: {
      color: theme.palette.primary.contrastText,
    },
    textArea: {
      color: theme.palette.primary.contrastText,
    },
    // Text of label when focused
    "& label.Mui-focused": {
      color: theme.palette.primary.contrastText,
      opacity: "100%",
      // backgroundColor: "transparent",
    },
    // "& label": {
    //   color: theme.palette.primary.contrastText,
    // },
    svg: {color: theme.palette.primary.contrastText},
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 2,
    },
    // color of the outline & text when field is filled in
    color: theme.palette.primary.contrastText,
    // color of the default text
    "& .MuiFormLabel-root": {
      color: theme.palette.primary.contrastText,
      opacity: "65%",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.contrastText,
      },
    },
    "& .MuiFormControl-rootMuiTextField-root input": {
      color: "yellow",
    },
  };
}

export function getSelectProps(theme) {
  return {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    "&.Mui-selected": {
      color: "white",
      fontWeight: 600,
    },
  };
}

export function getMenuItemProps(theme) {
  // https://stackoverflow.com/questions/69436218/how-to-change-the-dropdown-hover-color-react-material-ui-select
  return {
    "& .MuiMenu-paper": {
      backgroundColor: theme.palette.secondary.main,
    },
    "& .MuiMenuItem-root": {
      color: theme.palette.secondary.contrastText,
    },
    "&& .Mui-selected": {
      backgroundColor: theme.palette.tertiary.main,
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: theme.palette.secondary.hover
    },

  };
}

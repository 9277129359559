import { AppBar, Box, Divider, IconButton, Typography } from "@mui/material";
import AddMotorcycleOptions from "./AddMotorcycleOptions";
import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddNewMotorcycleForm from "./AddNewMotorcycleForm";
import { useRealmApp } from "../../../RealmApp";
import CloseIcon from "@mui/icons-material/Close";
import AddExistingMotorcycleForm from "./AddExistingMotorcycleForm";
import { useAllMotorcycleTemplatesForPartition } from "../../../graphql/useMotorcycleTemplateQueries";

function AddMotorcycleModal(props) {
  const [page, setPage] = useState(null);
  const onClose = props.onClose;
  const app = useRealmApp();
  const { motorcycleTemplates, loading } =
    useAllMotorcycleTemplatesForPartition(app.currentUser);

  let content = null;
  if (page === null) {
    content = (
      <Box sx={{ p: 2 }}>
        <AddMotorcycleOptions setPage={setPage} />
      </Box>
    );
  }

  if (page === "new") {
    content = (
      <AddNewMotorcycleForm
        user={app.currentUser}
        onClose={props.onClose}
      ></AddNewMotorcycleForm>
    );
  }
  if (page === "existing") {
    content = (
      <AddExistingMotorcycleForm
        loading={loading}
        user={app.currentUser}
        motorcycleTemplates={motorcycleTemplates}
        onClose={props.onClose}
      ></AddExistingMotorcycleForm>
    );
  }

  console.log(`page: ${page}`);

  return (
    <Box>
      <AppBar
        elevation={0}
        sx={{ backgroundColor: "transparent", p: 2, position: "relative" }}
      >
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            onClick={() => {
              setPage(null);
            }}
            disabled={page ? false : true}
          >
            <ArrowBackIcon
              sx={{ color: page ? "black" : "transparent" }}
            ></ArrowBackIcon>
          </IconButton>
          <Typography variant="h6" color={"black"}>
            Add motorcycle
          </Typography>
          <Box sx={{}}>
            <IconButton onClick={props.onClose}>
              <CloseIcon sx={{ color: "black" }}></CloseIcon>
            </IconButton>
          </Box>
        </Box>
      </AppBar>
      <Divider sx={{ p: 0, m: 0 }}></Divider>
      {content}
    </Box>
  );
}

export default AddMotorcycleModal;

/** @format */

import {gql, useQuery} from "@apollo/client";
import {useEffect} from "react";

export const GetAllScheduleTemplatesQuery = gql`
    query GetAllScheduleTemplatesForPartition($partition: String!) {
        scheduleTemplates(query: {
            _partition: $partition
        }) {
            _id
            id
            _partition
            datePeriodValue
            datePeriodUnit
            distancePeriodValue
            distancePeriodUnit
            name
            motorcycleTemplateId
        }
    }
`;

export const GetAllScheduleTemplatesForMotorcycleTemplateIdQuery = gql`
    query GetAllScheduleTemplatesForMotorcycleTemplateId(
        $motorcycleTemplateId: String!
    ) {
        scheduleTemplates(query: { motorcycleTemplateId: $motorcycleTemplateId }) {
            _id
            id
            _partition
            datePeriodValue
            datePeriodUnit
            distancePeriodValue
            distancePeriodUnit
            name
            motorcycleTemplateId
        }
    }
`;

export function useAllScheduleTemplatesForPartition(user) {
  const {data, loading, error} = useQuery(GetAllScheduleTemplatesQuery, {
    variables: {partition: `user=${user.id}`},
  });
  useEffect(() => {
  }, []);

  if (error) {
    throw new Error(`Failed to fetch schedule templates: ${error.message}`);
  }

  const scheduleTemplates = data?.scheduleTemplates ?? [];
  return {
    scheduleTemplates,
    loading,
  };
}

export function useAllScheduleTemplatesForMotorcycleTemplateId(
  motorcycleTemplateId
) {
  const {data, loading, error} = useQuery(
    GetAllScheduleTemplatesForMotorcycleTemplateIdQuery,
    {
      variables: {motorcycleTemplateId: `${motorcycleTemplateId}`},
    }
  );
  useEffect(() => {
  }, []);

  if (error) {
    throw new Error(`Failed to fetch schedule templates: ${error.message}`);
  }

  const scheduleTemplates = data?.scheduleTemplates ?? [];
  return {
    scheduleTemplates,
    loading,
  };
}

/** @format */

import { gql, useMutation } from "@apollo/client";
import { ObjectId } from "bson";

import React from "react";

export default function useTaskMutations(user) {
  return {
    addTask: useAddTask(user),
    updateTask: useUpdateTask(),
    deleteTask: useDeleteTask(),
  };
}

const AddTaskMutation = gql`
  mutation AddTask($task: TaskInsertInput!) {
    addedTask: insertOneTask(data: $task) {
      _id
      id
      maintenanceScheduleId
      odometer
      date
      distanceUnit
      note
    }
  }
`;

const UpdateTaskMutation = gql`
  mutation UpdateTask($taskId: String!, $updates: TaskUpdateInput!) {
    updatedTask: updateOneTask(query: { id: $taskId }, set: $updates) {
      _id
      id
      maintenanceScheduleId
      odometer
      date
      distanceUnit
      note
    }
  }
`;

const DeleteTaskMutation = gql`
  mutation DeleteTask($taskId: String!) {
    deletedTask: deleteOneTask(query: { id: $taskId }) {
      _id
      id
      maintenanceScheduleId
      odometer
      date
      distanceUnit
      note
    }
  }
`;

const TaskFieldsFragment = gql`
  fragment TaskFields on Task {
    _id
    id
    maintenanceScheduleId
    odometer
    date
    distanceUnit
    note
  }
`;

function useAddTask(user) {
  const [addTaskMutation] = useMutation(AddTaskMutation, {
    update: (cache, { data: { addedTask } }) => {
      cache.modify({
        fields: {
          tasks: (existingTasks = []) => [
            ...existingTasks,
            cache.writeFragment({
              data: addedTask,
              fragment: TaskFieldsFragment,
            }),
          ],
        },
      });
    },
  });

  const addTask = async (task) => {
    const { addedTask } = await addTaskMutation({
      variables: {
        task: {
          _id: new ObjectId(),
          _partition: `user=${user.id}`,
          id: task.id,
          maintenanceScheduleId: task.maintenanceScheduleId,
          odometer: task.odometer,
          date: task.date,
          distanceUnit: task.distanceUnit,
          note: task.note,
        },
      },
    });
    return addedTask;
  };
  return addTask;
}

function useUpdateTask() {
  const [updateTaskMutation] = useMutation(UpdateTaskMutation);
  const updateTask = async (task, updates) => {
    const { updatedTask } = await updateTaskMutation({
      variables: { taskId: task.id, updates },
    });
    return updatedTask;
  };
  return updateTask;
}

function useDeleteTask() {
  const [deleteTaskMutation] = useMutation(DeleteTaskMutation, {
    update: (cache, { data: { deletedTask } }) => {
      cache.modify({
        fields: {
          tasks(existingTaskRefs, { readField }) {
            return existingTaskRefs.filter(
              (taskRef) => deletedTask.id !== readField("id", taskRef)
            );
          },
        },
      });
    },
    // refetchQueries: ["GetAllMotorcyclesForPartition"],
  });
  const deleteTask = async (taskId) => {
    const { deletedTask } = await deleteTaskMutation({
      variables: { taskId: taskId },
    });
    return deletedTask;
  };
  return deleteTask;
}

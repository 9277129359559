import {Box} from "@mui/system";
import {Controller, useForm} from "react-hook-form";
import {MenuItem, Select, TextField, useTheme} from "@mui/material";
import {getMenuItemProps, getSelectProps, getTextfieldProps} from "../Styling/styling_utils";
import {isAfterToday} from "../Forms/validationFunctions";
import {dateToISOString} from "./utils";
import Button from "@mui/material/Button";
import * as PropTypes from "prop-types";

function Fragment(props) {
  return null;
}

Fragment.propTypes = {children: PropTypes.node};
export default function EditFinishedTaskItem(props) {
  const theme = useTheme();
  const today = new Date();
  const defaultDate = dateToISOString(today);

  const finishedTask = props.finishedTask

  const date = dateToISOString(new Date(finishedTask.date))
  const distanceUnit = finishedTask.distanceUnit
  const note = finishedTask.note
  const odometer = finishedTask.odometer
  const {handleSubmit, control, reset, register, errors} = useForm({
    defaultValues: {
      note: note,
      date: date,
      distanceUnit: distanceUnit,
      odometer: odometer
    },
  });

  function onSubmit(data) {
    console.log(data);
    const newTask = {
      note: data.note,
      date: new Date(data.date),
      distanceUnit: data.distanceUnit,
      odometer: data.odometer,
    };
    props.onUpdate(finishedTask, newTask)
    props.onClose();
  }

  return <Box sx={{
    display: "flex",
    flexDirection: "column",
    p: 2,
    gap: 2,
    borderRadius: 2,
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.secondary.contrastText
  }}>
    <Controller
      name="note"
      control={control}
      // rules={{ required: "Password required" }}
      render={({field: {onChange, value}, fieldState: {error}}) => (
        <TextField
          label={"Note"}
          multiline
          fullWidth={true}
          maxRows={5}
          value={value}
          sx={{
            my: 2,
            ...getTextfieldProps(theme),
          }}
          onChange={onChange}
          error={!!error}
          {...register("note")}
          helperText={error ? error.message : null}
        ></TextField>
      )}
    />
    <Box sx={{display: "flex", flexDirection: "row", gap: 2}}>
      <Controller
        name="odometer"
        control={control}
        rules={{
          required: {value: true, message: "Required"},
          min: {value: 0, message: "Must be positive"},
        }}
        render={({field: {onChange, value}, fieldState: {error}}) => (
          <TextField
            label={"Odometer"}
            type={"number"}
            fullWidth={true}
            value={value}
            sx={{
              my: 1,
              "& .MuiFormHelperText-root.Mui-error": {
                position: "absolute",
                top: "100%",
              },
              ...getTextfieldProps(theme),
            }}
            onChange={onChange}
            error={!!error}
            {...register("odometer")}
            helperText={error ? error.message : null}
          ></TextField>
        )}
      />
      <Controller
        name="distanceUnit"
        control={control}
        render={({field: {onChange, value}, fieldState: {error}}) => (
          <Select
            label={"label"}
            type={"number"}
            size="small"
            value={value}
            sx={{
              my: 1,
              ...getSelectProps(theme),
            }}
            MenuProps={{
              sx: {
                ...getMenuItemProps(theme),
              },
            }}
            onChange={onChange}
            error={!!error}
            {...register("distanceUnit")}
          >
            <MenuItem value={"km"}>{"km"}</MenuItem>
            <MenuItem value={"mi"}>{"mi"}</MenuItem>
          </Select>
        )}
      />
    </Box>
    <Controller
      name="date"
      control={control}
      rules={{
        required: "Required",
        validate: {
          inPast: (value) => !isAfterToday(value) || "Must be in the past.",
        },
      }}
      render={({field: {onChange, value}, fieldState: {error}}) => (
        <TextField
          label={"Date"}
          type={"date"}
          value={value}
          sx={{
            my: 2,
            input: {
              color: theme.palette.primary.contrastText,
            },
            label: {
              color: theme.palette.primary.contrastText,
            },
            ...getTextfieldProps(theme),
            svg: {backgroundColor: "#fff"}
          }}
          onChange={onChange}
          inputProps={{max: defaultDate}}
          error={!!error}
          {...register("date")}
          helperText={error ? error.message : null}
        ></TextField>
      )}
    />
    <Button variant={"contained"} color={"orange"} onClick={handleSubmit(onSubmit)}
    >Save changes</Button>
  </Box>
}
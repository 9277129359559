/** @format */

import {Box, ListItemButton, ListItemText} from "@mui/material";
import {Fragment} from "react";
import {useTheme} from "@emotion/react";
import ButtonWithDeleteAlert from "../UI/ButtonWithDeleteAlert";
import {calculateDisplayedDistance} from "./utils";
import Button from "@mui/material/Button";
import MenuWithSubmit from "../UI/MenuWithSubmit";
import EditFinishedTaskItem from "./EditFinishedTaskItem";

function FinishedTaskItem(props) {
  const finishedTask = props.finishedTask;
  const userDistanceUnit = localStorage.getItem("distanceUnit");
  const maintenanceSchedule = props.maintenanceSchedule;
  const convertedTaskOdometer = calculateDisplayedDistance(
    finishedTask.odometer,
    userDistanceUnit,
    finishedTask.distanceUnit || userDistanceUnit
  );

  const theme = useTheme();
  const selected = props.selected;

  function deleteFinishedTask() {
    props.onDelete(finishedTask.id);
  }

  return (
    <Fragment key={finishedTask.id}>
      <ListItemButton
        sx={{
          backgroundColor: theme.palette.tertiary.main,
          borderRadius: 1,
          display: "flex",
          justifyContent: "space-between",
          "&.Mui-selected, &.Mui-selected:hover, &:hover": {
            // border: 1,
            borderColor: theme.palette.secondary.main,
            backgroundColor: theme.palette.secondary.hover,
            boxShadow: 2,
            transition: "0.3s"
          },
          // height: 300,
        }}
        onClick={props.onClick}
        selected={selected}
      >
        <ListItemText
          primary={finishedTask.note ? finishedTask.note : "<No note>"}
          primaryTypographyProps={{
            noWrap: false,
            style: {wordWrap: "break-word"},
          }}
          sx={{width: "70%"}}
          // secondary={"Note: ..."}
        ></ListItemText>
        <ListItemText
          sx={{width: "30%"}}
          primary={`${convertedTaskOdometer} ${userDistanceUnit}`}
          secondary={`${
            new Date(finishedTask.date).toISOString().split("T")[0]
          }`}
          primaryTypographyProps={{textAlign: "right"}}
          secondaryTypographyProps={{
            textAlign: "right",
            color: theme.palette.primary.contrastText,
          }}
        ></ListItemText>
      </ListItemButton>
      {selected ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <MenuWithSubmit transformOrigin={{horizontal: "left"}}
                          button={<Button variant={"contained"} color={"secondary"} sx={{
                            "&:hover": {
                              backgroundColor: theme.palette.secondary.hover,
                              transition: 0.7
                            }
                          }}>Edit entry</Button>}>
            <EditFinishedTaskItem finishedTask={finishedTask} onUpdate={props.onUpdate}></EditFinishedTaskItem>
          </MenuWithSubmit>

          <ButtonWithDeleteAlert
            variant="contained"
            color="red"
            onDelete={deleteFinishedTask}
            sx={{
              marginLeft: "auto", "&:hover": {boxSizing: "content-box", backgroundColor: theme.palette.red.hover},
            }}
            title="Delete entry?"
            content={`Delete '${
              maintenanceSchedule.name
            }' entry performed at ${convertedTaskOdometer} ${userDistanceUnit} on ${
              new Date(finishedTask.date).toISOString().split("T")[0]
            }?`}
          >
            Delete entry
          </ButtonWithDeleteAlert>
        </Box>
      ) : null}
    </Fragment>
  );
}

export default FinishedTaskItem;

/** @format */
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, {Fragment} from "react";

const defaultAnchorOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
}
const defaultTransformOrigin = {
  vertical: 'top',
  horizontal: 'left',
}
const defaultBoxSx = {
  display: "flex",
  flexDirection: "column",
  m: 1,
  width: "30ch",
  gap: 2,
}

export default function MenuWithSubmit(props) {
  // Button which opens a menu, and hosts a page as children (children must be a react component)

  const childrenProps = props.childrenProps;
  const label = props.label;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);


  const anchorOrigin = {...defaultAnchorOrigin, ...props.anchorOrigin}
  const transformOrigin = {...defaultTransformOrigin, ...props.transformOrigin}
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleSubmit(object) {
    props.onSubmit(object);
    handleClose();
  }

  const button = props.button ? React.cloneElement(props.button, {onClick: handleClick}) : <Button
    color="orange"
    sx={{
      textTransform: "none",
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      "&:hover": {
        backgroundColor: "#fefefe35",
        color: "#fefefe",
      },
    }}
    onClick={handleClick}
  >
    <AddCircleIcon fontSize="small"></AddCircleIcon>
    <Typography noWrap variant="string" sx={{fontSize: 12}}>
      {label}
    </Typography>
  </Button>


  return (
    <Fragment>
      {button}
      <Menu
        anchorEl={anchorEl}
        open={open}
        variant={"menu"}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transitionDuration={{appear: 2000, enter: 200, exit: 0}}
        anchorOrigin={{...anchorOrigin}}
        transformOrigin={{...transformOrigin}}
        sx={{
          '& .MuiMenu-list': {p: 0, m: 0, borderRadius: 3, backgroundColor: "transparent"},
          '& .MuiMenu-root': {p: 0, m: 0, borderRadius: 3, backgroundColor: "transparent"},
          '& .MuiMenu-paper': {p: 0, m: 0, borderRadius: 0, backgroundColor: "transparent"}
        }}
      >
        {React.cloneElement(props.children, {
          onClose: handleClose,
          onSubmit: handleSubmit,
          ...childrenProps,
        })}
      </Menu>
    </Fragment>
  );
}


MenuWithSubmit.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  childrenProps: PropTypes.object,
  onSubmit: PropTypes.func,
};

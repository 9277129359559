/** @format */

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import React, { useEffect, useReducer } from "react";
import { useAllMotorcyclesForPartition } from "../../graphql/useMotorcycleQueries";
import useUserMutations from "../../graphql/useUserMutations";
import { useRealmApp } from "../../RealmApp";
import CONSTANTS from "../UI/Constants";
import AccountMenu from "./AccountMenu";
import SidebarList from "./SidebarList";
import SidebarPage from "./SidebarPage";
import { useTheme } from "@mui/material";

const sidebarWidth = CONSTANTS.sidebarWidth;

const defaultSelectedItems = {
  selectedSidebarItem: "home",
  selectedMotorcycleId: null,
};

function selectedItemsReducer(state, action) {
  if (action.type === "SELECT_SIDEBAR_ITEM") {
    return { selectedSidebarItem: action.value, selectedMotorcycleId: null };
  }
  if (action.type === "SELECT_MOTORCYCLE") {
    return {
      selectedSidebarItem: `motorcycle_${action.id}`,
      selectedMotorcycleId: action.id,
    };
  }
  return defaultSelectedItems;
}

function Sidebar(props) {
  const app = useRealmApp();
  const theme = useTheme();
  const { motorcycles, loading } = useAllMotorcyclesForPartition(
    app.currentUser
  );

  const { updateUser } = useUserMutations(app.currentUser);

  const [selectedItemsState, dispatchSelectedItems] = useReducer(
    selectedItemsReducer,
    defaultSelectedItems
  );

  function handleSelectSidebarItem(event, value) {
    dispatchSelectedItems({ type: "SELECT_SIDEBAR_ITEM", value: value });
  }

  function handleSelectMotorcycleId(event, id) {
    dispatchSelectedItems({ type: "SELECT_MOTORCYCLE", id: id });
  }

  useEffect(() => {});

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
      }}
    >
      {/*<CssBaseline></CssBaseline>*/}
      <Drawer
        PaperProps={{
          sx: {
            width: sidebarWidth,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            // boxSizing: "border-box",
            height: "100vh",
            borderRight: 1,
            borderColor: theme.palette.secondary.main,
          },
        }}
        sx={{
          width: sidebarWidth,
          flexShrink: 0,
        }}
        variant="permanent"
        anchor="left"
      >
        {/* <Toolbar sx={{ textAlign: "center" }}></Toolbar> */}
        <AccountMenu
          // username={app.currentUser.customData.name}
          username={app.currentUser._profile.data.email}
          onLogout={() => {
            app.logOut();
          }}
          onUpdateUser={updateUser}
          user={app.currentUser}
        ></AccountMenu>
        <SidebarList
          selectedItemsState={selectedItemsState}
          handleSelectSidebarItem={handleSelectSidebarItem}
          handleSelectMotorcycleId={handleSelectMotorcycleId}
          motorcycles={motorcycles}
          loadingMotorcycles={loading}
          user={app.currentUser}
        ></SidebarList>
      </Drawer>
      <Box sx={{ flexGrow: 1 }}>
        <SidebarPage
          motorcycles={motorcycles}
          selectedItemsState={selectedItemsState}
        ></SidebarPage>
      </Box>
      {props.children}
    </Box>
  );
}

export default Sidebar;

import { Box } from "@mui/system";
import useFormFieldObject, { getComponentValue } from "../../Forms/config";
import useForm from "../../Forms/useForm";
import { minValue, requiredRule } from "../../Forms/validationRules";
import { Fragment, useEffect, useState } from "react";
import { FormControl, InputLabel, Select } from "@mui/material";
import Button from "@mui/material/Button";
import getMenuItems from "../../UI/MenuItems";
import { useAllScheduleTemplatesForMotorcycleTemplateId } from "../../../graphql/useScheduleTemplateQueries copy";
import { useForm as useForm2 } from "react-hook-form";
import useScheduleMutations from "../../../graphql/useScheduleMutations";
import useMotorcycleMutations from "../../../graphql/useMotorcycleMutations";
import { useAllSchedulesForPartition } from "../../../graphql/useScheduleQueries";

function AddExistingMotorcycleForm(props) {
  const { register } = useForm2();
  const motorcycleTemplates = props.motorcycleTemplates;
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [year, setYear] = useState("");
  const { addMotorcycle } = useMotorcycleMutations(props.user);
  const [currentMotorcycleTemplate, setCurrentMotorcycleTemplate] =
    useState(null);

  const { refetch: refetchTemplates } = useAllSchedulesForPartition(props.user);
  const { addMaintenanceSchedules } = useScheduleMutations(props.user);
  const { scheduleTemplates, loading } =
    useAllScheduleTemplatesForMotorcycleTemplateId(
      currentMotorcycleTemplate?.id
    );

  // we always want to show all brands
  const brands = [
    ...new Set(
      motorcycleTemplates.map((template) => {
        return template.brand;
      })
    ),
  ].sort();

  useEffect(() => {
    setModel("");
    setYear("");
  }, [brand]);

  useEffect(() => {
    setYear("");
  }, [model]);

  const motorcyclesWithValidBrands = motorcycleTemplates.filter((template) => {
    if (brand) {
      return template.brand === brand;
    } else {
      return true;
    }
  });

  const models = [
    ...new Set(
      motorcyclesWithValidBrands.map((template) => {
        return template.model;
      })
    ),
  ].sort();

  const motorcyclesWithValidModels = motorcyclesWithValidBrands.filter(
    (template) => {
      if (model) {
        return template.model === model;
      } else {
        return true;
      }
    }
  );

  const years = [
    ...new Set(
      motorcyclesWithValidModels.map((template) => {
        return template.year;
      })
    ),
  ].sort();

  const brandMenuItems = getMenuItems(brands);
  const modelMenuItems = getMenuItems(models);
  const yearMenuItems = getMenuItems(years);

  const brandSelectComponent = (
    <FormControl sx={{ width: "100%", textAlign: "left" }} size={"small"}>
      <InputLabel>Brand</InputLabel>
      <Select
        label={"Brand"}
        value={brand}
        displayEmpty={true}
        onChange={(event) => {
          console.log("click!");
          setBrand(event.target.value);
        }}
      >
        {brandMenuItems}
      </Select>
    </FormControl>
  );
  const modelSelectComponent = (
    <FormControl sx={{ width: "100%", textAlign: "left" }} size={"small"}>
      <InputLabel>Model</InputLabel>
      <Select
        label={"Model"}
        value={model}
        disabled={brand === ""}
        defaultValue={""}
        displayEmpty={true}
        // disabled={brand === ""}
        onChange={(event) => {
          setModel(event.target.value);
        }}
      >
        {modelMenuItems}
      </Select>
    </FormControl>
  );

  const yearSelectComponent = (
    <FormControl sx={{ width: "100%", textAlign: "left" }} size={"small"}>
      <InputLabel>Year</InputLabel>
      <Select
        label={"Year"}
        value={year}
        displayEmpty={true}
        disabled={model === ""}
        sx={{ textAlign: "left" }}
        onChange={(event) => {
          setYear(event.target.value);
        }}
      >
        {yearMenuItems}
      </Select>
    </FormControl>
  );

  const selectComponents = (
    <Fragment>
      {brandSelectComponent}
      {modelSelectComponent}
      {yearSelectComponent}
    </Fragment>
  );
  console.log(`brands: ${brands}`);
  const formObject = {
    odometer: {
      ...useFormFieldObject({
        components: [
          {
            label: "Odometer",
            name: "odometer",
            type: "number",
            defaultUnitValue: "km",
            unitValues: ["km", "mi"],
            validationRules: [
              requiredRule("odometer"),
              minValue("odometer", 0),
            ],
          },
        ],
      }),
    },
  };

  const { form, renderFormInputs, isFormValid, validateForm } =
    useForm(formObject);
  const { isValid, ref } = isFormValid();

  const formIsValid =
    isValid && brand !== "" && year !== "" && model !== "" && !loading;

  useEffect(() => {
    if (formIsValid) {
      const chosenMotorcycleTemplate = motorcycleTemplates.find((template) => {
        return (
          template.brand === brand &&
          template.year === year &&
          template.model === model
        );
      });
      setCurrentMotorcycleTemplate({ ...chosenMotorcycleTemplate });

      console.log(
        `CHOSEN MOTORCYCLE TEMPLATE ID: ${chosenMotorcycleTemplate.id}`
      );
    }
  }, [formIsValid, brand, year, model]);
  console.log(`ScheduleTemplates: ${scheduleTemplates}`);

  function onSubmit() {
    if (!isValid && ref) {
      ref.current.focus();
    } else if (isValid) {
      console.log(`${JSON.stringify(scheduleTemplates)}`);
      const motorcycleId = Math.random().toString(36).substr(2, 9).toString();
      const newSchedules = scheduleTemplates.map((template) => {
        return {
          name: template.name,
          datePeriodUnit: template.datePeriodUnit,
          datePeriodValue: template.datePeriodValue,
          distancePeriodUnit: template.distancePeriodUnit,
          distancePeriodValue: template.distancePeriodValue,
          id: Math.random().toString(36).substr(2, 9).toString(),
          motorcycleId: motorcycleId,
          startingDate: new Date(),
        };
      });
      const newMotorcycle = {
        brand: currentMotorcycleTemplate.brand,
        model: currentMotorcycleTemplate.model,
        year: +currentMotorcycleTemplate.year,
        id: motorcycleId,
        odometer: +getComponentValue(form.odometer.componentsInfo, "odometer"),
        distanceUnit: currentMotorcycleTemplate.distanceUnit,
      };

      addMotorcycle(newMotorcycle);
      addMaintenanceSchedules(newSchedules);
      // refetchTemplates();
      props.onClose();
    }
  }

  return (
    <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
      {selectComponents}
      {renderFormInputs()}
      {loading ? "Loading...." : null}
      <Button variant={"outlined"} disabled={!formIsValid} onClick={onSubmit}>
        Add
      </Button>
    </Box>
  );
  // console.log(validMotorcycleTemplates);
}

export default AddExistingMotorcycleForm;

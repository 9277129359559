/** @format */
import {
  Fragment,
  useContext,
  useState,
  useEffect,
  useRef,
  useReducer,
} from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React from "react";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import useMotorcycleMutations from "../../graphql/useMotorcycleMutations";
import { useRealmApp } from "../../RealmApp";
import DistancePeriodTextField from "./AddMaintenanceSchedule/DistancePeriodTextField";
import { calculateDisplayedDistance } from "./utils";

function motorcycleOdometerReducer(state, action) {
  if (action.type === "USER_INPUT") {
    return {
      value: action.value,
      isValid: action.value.toString().trim() !== "" && +action.value > 0,
    };
  }
  if (action.type === "CLEAR") {
    return { value: "", isValid: null };
  }
  if (action.type === "VALIDATE") {
    return {
      value: state.value,
      isValid: state.value.toString().trim() !== "" && +state.value >= 0,
    };
  }
}

function UpdateOdometer(props) {
  const motorcycleOdometerRef = useRef();
  const app = useRealmApp();
  const { updateMotorcycle } = useMotorcycleMutations(app.currentUser);
  const motorcycle = props.motorcycle;

  const userDistanceUnit = localStorage.getItem("distanceUnit");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [motorcycleOdometerState, dispatchMotorcycleOdometerAction] =
    useReducer(motorcycleOdometerReducer, {
      value: calculateDisplayedDistance(
        motorcycle.odometer,
        userDistanceUnit,
        motorcycle.distanceUnit || userDistanceUnit
      ),
      isValid: null,
    });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDistanceUnit(userDistanceUnit);
  };

  function handleMotorcycleOdometerChange(event) {
    dispatchMotorcycleOdometerAction({
      type: "USER_INPUT",
      value: event.target.value,
    });
  }

  function validateMotorcycleOdometer() {
    dispatchMotorcycleOdometerAction({
      type: "VALIDATE",
    });
  }

  // distance Unit
  const [distanceUnit, setDistanceUnit] = React.useState(userDistanceUnit);
  function handleDistanceUnitChange(event) {
    setDistanceUnit(event.target.value);
  }
  function resetDistanceUnit() {
    setDistanceUnit(userDistanceUnit);
  }

  function handleUpdate() {
    if (motorcycleOdometerState.isValid) {
      // motorcyclesContext.updateMotorcycleOdometer(
      //   motorcycle.id,
      //   motorcycleOdometerState.value
      // );
      updateMotorcycle(motorcycle, {
        odometer: motorcycleOdometerState.value,
        distanceUnit: distanceUnit,
      });
      handleClose();
    } else {
      validateMotorcycleOdometer();
      motorcycleOdometerRef.current.focus();
    }
  }

  useEffect(() => {
    dispatchMotorcycleOdometerAction({
      type: "USER_INPUT",
      value: calculateDisplayedDistance(
        motorcycle.odometer,
        userDistanceUnit,
        motorcycle.distanceUnit || userDistanceUnit
      ),
    });
    setDistanceUnit(userDistanceUnit);
  }, [motorcycle.distanceUnit, userDistanceUnit, motorcycle.odometer]);

  return (
    <Fragment>
      <Button
        onClick={handleClick}
        variant="outlined"
        color="inherit"
        size="small"
        sx={{ textTransform: "none", ml: 3, my: 2 }}
      >
        <Typography noWrap variant="p" sx={{ fontSize: 12 }}>
          Update Odometer
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transitionDuration={{ appear: 2000, enter: 200, exit: 0 }}
      >
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <DistancePeriodTextField
            error={motorcycleOdometerState.isValid === false}
            ref={motorcycleOdometerRef}
            value={motorcycleOdometerState.value}
            label="Odometer stance"
            onValidate={validateMotorcycleOdometer}
            unit={distanceUnit}
            onValueChange={handleMotorcycleOdometerChange}
            onUnitChange={handleDistanceUnitChange}
          ></DistancePeriodTextField>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1, m: 1 }}>
          <Button variant="outlined" size="small" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" size="small" onClick={handleUpdate}>
            Update
          </Button>
        </Box>
      </Menu>
    </Fragment>
  );
}

export default UpdateOdometer;

/** @format */

import { gql, useMutation } from "@apollo/client";

import React from "react";

export default function useUserMutations(user) {
  return {
    updateUser: useUpdateUser(),
    deleteUser: useDeleteUser(),
  };
}

const UpdateUserMutation = gql`
  mutation UpdateUser($id: String!, $updates: UserUpdateInput!) {
    updatedUser: updateOneUser(query: { id: $id }, set: $updates) {
      id
      _partition
      name
      distanceUnit
      creationDate
    }
  }
`;

const DeleteUserMutation = gql`
  mutation DeleteUser($userId: String!) {
    deletedUser: deleteOneUser(query: { _id: $userId }) {
      _id
      id
      _partition
      name
      distanceUnit
      creationDate
    }
  }
`;

const UserFieldsFragment = gql`
  fragment UserFields on User {
    _id
    id
    _partition
    distanceUnit
    name
    creationDate
  }
`;

function useUpdateUser() {
  const [updateUserMutation] = useMutation(UpdateUserMutation);
  const updateUser = async (user, updates) => {
    const { updatedUser } = await updateUserMutation({
      variables: { id: `${user.id}`, updates },
    });
    return updatedUser;
  };
  return updateUser;
}

function useDeleteUser() {
  const [deleteUserMutation] = useMutation(DeleteUserMutation, {
    update: (cache, { data: { deletedUser } }) => {
      cache.modify({
        fields: {
          tasks(existingUserRefs, { readField }) {
            return existingUserRefs.filter(
              (userRef) => deletedUser.id !== readField("id", userRef)
            );
          },
        },
      });
    },
    // refetchQueries: ["GetAllMotorcyclesForPartition"],
  });
  const deleteUser = async (userId) => {
    const { deletedUser } = await deleteUserMutation({
      variables: { userId: userId },
    });
    return deletedUser;
  };
  return deleteUser;
}

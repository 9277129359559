/**
 * creates and returns a validation rule object that
 * is used by useForm hook to validate the form inputs
 *
 * @format
 * @param {string} ruleName - name of the validation rule
 * @param {string} errorMessage - message to display
 * @param {function} validateFunc - validation function
 */

import { dateIsValid } from "../../hooks/reducers";

function createValidationRule(ruleName, errorMessage, validateFunc) {
  return {
    name: ruleName,
    message: errorMessage,
    validate: validateFunc,
  };
}

export function requiredRule(inputName) {
  return createValidationRule(
    "required",
    `${inputName} required`,
    (inputValue, formObj) => {
      return inputValue.length !== 0;
    }
  );
}

export function minValue(inputName, minValue) {
  return createValidationRule(
    "min value",
    `${inputName} must be greater than ${minValue}`,
    (inputValue, formObj) => {
      return +inputValue >= minValue;
    }
  );
}

/**
 *
 * @param {string} inputName
 * @param {string} maxDate 313531
 * @returns
 */
export function maxDateRule(inputName) {
  return createValidationRule(
    "max date",
    `${inputName} cannot be after today.`,
    (inputValue, formObj) => {
      return dateIsValid(inputValue);
    }
  );
}

import { Box, Typography } from "@mui/material";

function AddMotorcycleOptionButton(props) {
  const label = props.label;
  const subtitle = props.subtitle;
  const onClick = props.onClick;
  const icon = props.icon;
  return (
    <Box
      sx={{
        backgroundColor: "lightgray",
        "&.Mui-selected, &.Mui-selected:hover, &:hover": {
          backgroundColor: "#DFDFDF",
        },
        pb: 5,
        pt: 2,
        px: 2,
        borderRadius: 2,
        display: "flex",
        flexDirection: "row",
        gap: 2,
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          flexDirection: "column",
        }}
      >
        {icon}
      </Box>
      <Box sx={{ textAlign: "left", border: 0 }}>
        <Typography variant="h6">{label}</Typography>
        <Typography variant="subtitle2">{subtitle}</Typography>
      </Box>
    </Box>
  );
}

export default AddMotorcycleOptionButton;

/** @format */

import { gql, useQuery } from "@apollo/client";
import React, { useEffect } from "react";

export const GetAllTasksQuery = gql`
  query GetAllTasksForPartition($partition: String!) {
    tasks(query: { _partition: $partition }) {
      _id
      id
      maintenanceScheduleId
      odometer
      date
      distanceUnit
      note
    }
  }
`;

export function useAllTasksForPartition(user) {
  const { data, loading, error } = useQuery(GetAllTasksQuery, {
    variables: { partition: `user=${user.id}` },
  });
  useEffect(() => {}, []);

  if (error) {
    throw new Error(`Failed to fetch tasks: ${error.message}`);
  }

  const tasks = data?.tasks ?? [];
  return {
    tasks,
    loading,
  };
}

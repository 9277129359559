/** @format */

import moment from "moment";
import { dateToISOString } from "../SelectedMotorcycle/utils";

export function getDefaultDate() {
  const today = new Date();
  return dateToISOString(today);
}

function dateChecker(dateString1, dateString2) {
  return moment(dateString1, "YYYY-MM-DD") <= moment(dateString2, "YYYY-MM-DD");
}

export function nonemptyStringIsValid(string) {
  return string.trim() !== "";
}

export function dateIsValid(string) {
  return dateChecker(string, getDefaultDate());
}

export function positiveNumberIsValid(string) {
  return string.trim() !== "" && +string > 0;
}

export function nonNegativeNumberIsValid(string) {
  return string.trim() !== "" && +string >= 0;
}

export const isAfterToday = (date) => {
  if (!date) {
    return false;
  }
  const today = dateToISOString(new Date());
  console.log(`date: ${date}. today: ${today}`);
  return date > today;
};

import {Button, Typography} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React, {Fragment, useEffect} from "react";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import {useTheme} from "@emotion/react";
import AddFinishedTaskForm from "./AddFinishedTaskForm";

export default function AddFinishedTask(props) {
  const maintenanceSchedule = props.maintenanceSchedule;
  const motorcycle = props.motorcycle;
  const [addMaintenanceOpen, setAddMaintenanceOpen] = React.useState(false);
  const theme = useTheme();

  function clickAddMaintenance() {
    setAddMaintenanceOpen(!addMaintenanceOpen);
  }

  useEffect(() => {
    resetAllState();
  }, [maintenanceSchedule]);

  function resetAllState() {
    setAddMaintenanceOpen(false);
  }

  return (
    <Fragment>
      {!addMaintenanceOpen && (
        <Button
          elevation={0}
          variant="outlined"
          color="inherit"
          sx={{
            textTransform: "none",
            my: 3,
            border: 0,
            boxShadow: 0,
            px: 0,
            justifyContent: "flex-start",
            "&:hover": {
              color: "red",
            },
          }}
          onClick={clickAddMaintenance}
        >
          <AddCircleIcon fontSize="small" sx={{border: 0}}></AddCircleIcon>

          <Typography noWrap variant="p">
            Add entry
          </Typography>
        </Button>
      )}
      <Collapse
        in={addMaintenanceOpen}
        timeout={0}
        unmountOnExit
        sx={{
          my: 3,
        }}
      >
        <Box
          component="form"
          sx={{
            // "& > :not(style)": { m: 1, width: "25ch" },
            display: "flex",
            flexDirection: "column",
            borderRadius: 3,
            p: 2,
            gap: 2,
          }}
          backgroundColor={theme.palette.tertiary.main}
          color={theme.palette.secondary.contrastText}
        >
          <AddFinishedTaskForm
            motorcycle={props.motorcycle}
            maintenanceSchedule={maintenanceSchedule}
            onClose={() => setAddMaintenanceOpen(false)}
          ></AddFinishedTaskForm>
        </Box>
      </Collapse>
    </Fragment>
  );
}

import { useTheme } from "@mui/system";
import Container from "@mui/material/Container";
import * as React from "react";
import Typography from "@mui/material/Typography";
import { TwoWheeler } from "@mui/icons-material";
import Grid from "@mui/material/Grid";

export default function MobilePage(props) {
  const theme = useTheme();
  return (
    <Container component="main" maxWidth="xs" justifyContent="center">
      {/*<CssBaseline />*/}
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          // top: "20vh",
          position: "relative",
          justifyContent: "center",
          minHeight: "60vh",
        }}
      >
        <TwoWheeler
          sx={{
            // animation: "spin 5s linear infinite",
            // "@keyframes spin": {
            //   "0%": {
            //     transform: "rotate(360deg)",
            //   },
            //   "100%": {
            //     transform: "rotate(0deg)",
            //   },
            // },
            color: "white",
            width: 60,
            height: 60,
          }}
        ></TwoWheeler>
        <Typography variant={"h6"} color={"white"}>
          MotoMaintenance
        </Typography>
        <Typography color={"white"}>Coming to mobile soon</Typography>
      </Grid>
    </Container>
  );
}

import {FormHelperText, MenuItem, Select, TextField, useTheme} from "@mui/material";
import {useRealmApp} from "../../RealmApp";
import {Controller, useForm} from "react-hook-form";
import FormCard from "../UI/FormCard";
import FormTextFieldProps from "../UI/FormTextFieldProps";
import {Box} from "@mui/system";
import Button from "@mui/material/Button";
import {getMenuItemProps, getSelectProps} from "../Styling/styling_utils";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function AddEditScheduleTemplateForm(props) {
  const theme = useTheme();
  const app = useRealmApp();

  const scheduleTemplate = props.scheduleTemplate
  const {handleSubmit, control, reset, register, errors, getValues, watch} = useForm({
    defaultValues: {
      name: scheduleTemplate?.name || "",
      distancePeriodValue: scheduleTemplate?.distancePeriodValue || "",
      distancePeriodUnit: scheduleTemplate?.distancePeriodUnit || localStorage.getItem("distanceUnit"),
      datePeriodValue: scheduleTemplate?.datePeriodValue || "",
      datePeriodUnit: scheduleTemplate?.datePeriodUnit || "days",
      useDistancePeriod: scheduleTemplate?.distancePeriodValue ? true : !scheduleTemplate,
      useDatePeriod: scheduleTemplate?.datePeriodValue ? true : !scheduleTemplate,
    },
  });

  const onSave = props.onSave
  const onUpdate = props.onUpdate
  const watchUseDistancePeriod = watch("useDistancePeriod")
  const watchUseDatePeriod = watch("useDatePeriod")
  const formIsValid = watchUseDistancePeriod || watchUseDatePeriod
  console.log(`formIsValid: ${formIsValid}`)

  function onSubmit(data) {
    console.log(data);
    if (onSave && formIsValid) {
      const newScheduleTemplate = {
        name: data.name,
        distancePeriodValue: data.distancePeriodValue ? data.distancePeriodValue : null,
        distancePeriodUnit: data.distancePeriodUnit,
        datePeriodValue: data.datePeriodValue ? data.datePeriodValue : null,
        datePeriodUnit: data.datePeriodUnit,
        id: Math.random().toString(36).substr(2, 9).toString(),
        motorcycleTemplateId: props.motorcycleTemplate.id,
      };
      onSave(newScheduleTemplate)
    } else if (onUpdate) {
      const newScheduleTemplate = {
        name: data.name,
        distancePeriodValue: data.distancePeriodValue ? data.distancePeriodValue : null,
        distancePeriodUnit: data.distancePeriodUnit,
        datePeriodValue: data.datePeriodValue ? data.datePeriodValue : null,
        datePeriodUnit: data.datePeriodUnit,
      };
      onUpdate(scheduleTemplate, newScheduleTemplate)
      console.log(`updating schedule template!`)
    }

    props.onClose();
  }

  return (
    <FormCard sx={{m: 2}}>
      <Controller
        name="name"
        control={control}
        rules={{
          required: {value: true, message: "Required"},
        }}
        render={({field: {onChange, value}, fieldState: {error}}) => (
          <TextField
            label={"Name"}
            fullWidth={true}
            value={value}
            sx={FormTextFieldProps()}
            onChange={onChange}
            error={!!error}
            {...register("name")}
            helperText={error ? error.message : null}
          ></TextField>
        )}
      />
      <Box sx={{
        border: 1,
        borderRadius: 2,
        p: 1,
        borderColor: theme.palette.secondary.main,
        display: "flex",
        flexDirection: "column",
      }}>
        <Box sx={{display: "flex", flexDirection: "row", gap: 2}}>
          <Controller
            name="distancePeriodValue"
            control={control}
            rules={{
              validate: {
                test: (value) => {
                  if (!value && getValues("useDistancePeriod")) {
                    return "Required";
                  } else return true;
                },
              },
            }}
            render={({field: {onChange, value}, fieldState: {error}}) => (
              <TextField
                label={"Repeat every"}
                multiline
                fullWidth={true}
                value={value}
                sx={{
                  ...FormTextFieldProps()
                }}
                onChange={onChange}
                error={!!error}
                {...register("distancePeriodValue")}
                helperText={error ? error.message : null}
              ></TextField>
            )}
          />
          <Controller
            name="distancePeriodUnit"
            control={control}
            render={({field: {onChange, value}, fieldState: {error}}) => (
              <Select
                label={""}
                type={"number"}
                value={value}
                size="small"
                sx={{
                  // my: 1,
                  ...getSelectProps(theme),
                  ...FormTextFieldProps()
                }}
                onChange={onChange}
                MenuProps={{
                  sx: {
                    ...getMenuItemProps(theme),
                  },
                }}
                error={!!error}
                {...register("distancePeriodUnit")}
              >
                <MenuItem value={"km"}>{"km"}</MenuItem>
                <MenuItem value={"mi"}>{"mi"}</MenuItem>
              </Select>
            )}
          />
        </Box>
        <Controller
          name="useDistancePeriod"
          control={control}
          render={({field: props, fieldState: {error}}) => (
            <FormControlLabel
              label={"Include"}
              control={
                <Checkbox
                  checked={props.value}
                  {...props}
                  style={{
                    color: theme.palette.primary.contrastText,
                  }}
                  onChange={(e) => {
                    props.onChange(e.target.checked);
                  }}
                  sx={{my: 1}}
                ></Checkbox>
              }
            ></FormControlLabel>
          )}
        />
      </Box>
      <Box sx={{
        border: 1,
        borderRadius: 2,
        p: 1,
        borderColor: theme.palette.secondary.main,
        display: "flex",
        flexDirection: "column",
      }}>
        <Box sx={{display: "flex", flexDirection: "row", gap: 2}}>
          <Controller
            name="datePeriodValue"
            control={control}
            rules={{
              validate: {
                test: (value) => {
                  if (!value && getValues("useDatePeriod")) {
                    return "Required";
                  } else return true;
                },
              },
            }}
            render={({field: {onChange, value}, fieldState: {error}}) => (
              <TextField
                label={"Repeat every"}
                multiline
                fullWidth={true}
                value={value}
                sx={{
                  ...FormTextFieldProps(),
                }}
                onChange={onChange}
                error={!!error}
                {...register("datePeriodValue")}
                helperText={error ? error.message : null}
              ></TextField>
            )}
          />
          <Controller
            name="datePeriodUnit"
            control={control}
            render={({field: {onChange, value}, fieldState: {error}}) => (
              <Select
                label={""}
                type={"number"}
                // variant={"filled"}
                value={value}
                sx={{
                  ...FormTextFieldProps(),
                  ...getSelectProps(theme),
                }}
                onChange={onChange}
                MenuProps={{
                  sx: {
                    ...getMenuItemProps(theme),
                  },
                }}
                error={!!error}
                {...register("datePeriodUnit")}
              >
                <MenuItem value={"days"}>days</MenuItem>
                <MenuItem value={"weeks"}>weeks</MenuItem>
                <MenuItem value={"months"}>months</MenuItem>
                <MenuItem value={"years"}>years</MenuItem>
              </Select>
            )}
          />
        </Box>
        <Controller
          name="useDatePeriod"
          control={control}
          render={({field: props, fieldState: {error}}) => (
            <FormControlLabel
              label={"Include"}
              control={
                <Checkbox
                  checked={props.value}
                  {...props}
                  style={{
                    color: theme.palette.primary.contrastText,
                  }}
                  onChange={(e) => {
                    props.onChange(e.target.checked);
                  }}
                  sx={{my: 1}}
                ></Checkbox>
              }
            ></FormControlLabel>
          )}
        />
      </Box>
      {formIsValid ? null : <FormHelperText error={true}>Must include at least one</FormHelperText>}
      <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <Button variant={"contained"} color={"secondary"} onClick={props.onClose}>Cancel</Button>
        <Button
          variant={"contained"}
          color={"orange"}
          onClick={handleSubmit(onSubmit)}
          disabled={!formIsValid}
        >
          Save
        </Button>
      </Box>
    </FormCard>);
}
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import * as React from "react";
import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import validator from "validator";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useRealmApp } from "../../RealmApp";

export default function PasswordResetEmail(props) {
  // Keep track of form input state
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState({});
  const [helperText, setHelperText] = React.useState("");
  const app = useRealmApp();

  useEffect(() => {
    if (email.length > 0) {
      setHelperText("");
    }
  }, [email]);

  function handleResetPassword() {
    const isValidEmailAddress = validator.isEmail(email);
    if (isValidEmailAddress) {
      try {
        // Send password reset email
        app.emailPasswordAuth.sendResetPasswordEmail({ email });
        setEmail("");
        setHelperText("Sent password email!");
      } catch (err) {
        //
      }
    } else {
      setError((err) => ({ ...err, email: "Email is invalid." }));
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          // top: "20vh",
          position: "relative",
          justifyContent: "center",
          minHeight: "60vh",
        }}
      >
        <Typography variant={"h6"} color={"white"}>
          Reset Password
        </Typography>
        <TextField
          error={error.email}
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={(e) => {
            setError((e) => ({ ...e, email: null }));
            setEmail(e.target.value);
          }}
          helperText={error.email ? `${error.email}` : ""}
          value={email}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleResetPassword}
        >
          Reset
        </Button>
        {helperText ? helperText : ""}
      </Grid>
    </Container>
  );
}

/** @format */

import {Close, Edit} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {IconButton, useTheme} from "@mui/material";
import {Box} from "@mui/system";
import {useEffect, useState} from "react";
import useScheduleTemplateMutations from "../../graphql/useScheduleTemplateMutations";
import {useAllScheduleTemplatesForMotorcycleTemplateId} from "../../graphql/useScheduleTemplateQueries copy";
import ButtonWithDeleteAlert from "../UI/ButtonWithDeleteAlert";
import AddScheduleTemplate from "./AddScheduleTemplate";
import MotorcycleTemplateView from "./MotorcycleTemplateView";
import ScheduleTemplatesList from "./ScheduleTemplatesList";
import AddEditMotorcycleTemplateForm from "./AddEditMotorcycleTemplateForm";

export default function MotorcycleTemplate(props) {
  const [editMode, setEditMode] = useState(false);
  const theme = useTheme();
  const {
    addScheduleTemplate,
    updateScheduleTemplate,
    deleteScheduleTemplate,
  } = useScheduleTemplateMutations(props.user);

  const {scheduleTemplates, loading} = useAllScheduleTemplatesForMotorcycleTemplateId(
    props.template?.id
  );

  const scheduleTemplatesForThisMotorcycleTemplate = scheduleTemplates.filter(
    (template) => template.motorcycleTemplateId === props.template?.id
  );

  useEffect(() => {
    setEditMode(false);
  }, [props.template]);

  if (!props.template) {
    return null;
  }

  let templateContent = null;
  if (editMode === true) {
    templateContent = (
      <AddEditMotorcycleTemplateForm motorcycleTemplate={props.template} sx={{m: 2}}
                                     onUpdate={(oldTemplate, newTemplate) => {
                                       props.onEdit(oldTemplate, newTemplate);
                                       setEditMode(false);
                                     }}
                                     onClose={() => {
                                       setEditMode(false)
                                     }}
      >
      </AddEditMotorcycleTemplateForm>
    );
  } else {
    templateContent = (
      <MotorcycleTemplateView
        template={props.template}
      ></MotorcycleTemplateView>
    );
  }
  return (
    <Box
      sx={{
        border: 1,
        borderColor: theme.palette.secondary.main,
        width: "30vw",
        backgroundColor: theme.palette.primary.main,
        maxHeight: "100%",
        overflowY: "scroll",
      }}
    >
      <Box sx={{display: "flex"}}>
        <IconButton
          onClick={() => {
            setEditMode((prevEditMode) => {
              return !prevEditMode;
            });
          }}
          sx={{
            marginLeft: "auto",
            flexGrow: 0,
            color: theme.palette.secondary.main,
          }}
        >
          {editMode === false ? <Edit></Edit> : <Close></Close>}
        </IconButton>
        <ButtonWithDeleteAlert
          variant="outlined"
          color="secondary"
          type="icon"
          onDelete={props.onDelete.bind(null, props.template)}
          sx={{
            border: 0,
            boxSizing: "border-box",
            "&:hover": {border: 0},
          }}
          title={`Delete this motorcycle template?`}
          content={``}
        >
          <DeleteIcon></DeleteIcon>
        </ButtonWithDeleteAlert>
      </Box>
      {templateContent}
      <AddScheduleTemplate
        onAdd={addScheduleTemplate}
        motorcycleTemplate={props.template}
      ></AddScheduleTemplate>
      <ScheduleTemplatesList
        items={scheduleTemplatesForThisMotorcycleTemplate}
        motorcycleTemplate={props.template}
        onDelete={deleteScheduleTemplate}
        onUpdate={updateScheduleTemplate}
      ></ScheduleTemplatesList>
    </Box>
  );
}

/** @format */
import { Delete } from "@mui/icons-material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, ListItemButton, ListItemText, useTheme } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import React, { Fragment, useState } from "react";
import useMotorcycleMutations from "../../graphql/useMotorcycleMutations";
import { useRealmApp } from "../../RealmApp";
import {
  calculateDisplayedDistance,
  getMotorcycleName,
} from "../SelectedMotorcycle/utils";
import ButtonWithDeleteAlert from "../UI/ButtonWithDeleteAlert";
import Loading from "../UI/Loading";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import { useSubscription } from "../../store/SubscriptionProvider";

function MotorcyclesList(props) {
  const app = useRealmApp();
  const theme = useTheme();
  const { deleteMotorcycle } = useMotorcycleMutations(app.currentUser);
  const items = props.items;
  const listItemButtonSx = props.listItemButtonSx;
  const selectedItemId = props.selectedItemId;
  const [motorcyclesListOpen, setMotorcyclesListOpen] = React.useState(true);

  function clickMotorcyclesListHandler() {
    setMotorcyclesListOpen(!motorcyclesListOpen);
  }

  const [mouseOverIndex, setMouseOverIndex] = useState(null);

  function onMouseOver(index) {
    setMouseOverIndex(index);
  }

  const userDistanceUnit = localStorage.getItem("distanceUnit");
  const subscription = useSubscription();
  const subscriptionActive = subscription.active;

  function disableMotorcycle(index, subscriptionActive) {
    if (subscriptionActive) {
      return false;
    }
    if (index > 0) {
      return true;
    }
  }

  const listItems = items.map((item, index) => {
    return (
      <Box
        onMouseLeave={onMouseOver.bind(null, null)}
        onMouseOver={onMouseOver.bind(null, index)}
        key={index}
      >
        <ListItemButton
          sx={listItemButtonSx}
          key={item.id}
          onClick={props.onClick.bind(null, null, item.id)}
          selected={selectedItemId === item.id}
          disabled={disableMotorcycle(index, subscriptionActive)}
          divider={true}
        >
          <ButtonWithDeleteAlert
            sx={{
              color:
                mouseOverIndex === index
                  ? theme.palette.red.main
                  : "transparent",
              "&:hover": {
                backgroundColor:
                  mouseOverIndex === index
                    ? theme.palette.red.backgroundColor
                    : "transparent",
              },
            }}
            onDelete={() => {
              deleteMotorcycle(item);
            }}
            type="icon"
            title="Delete Motorcycle?"
            content={`Delete ${getMotorcycleName(
              item
            )}? This can not be undone!`}
          >
            <Delete></Delete>
          </ButtonWithDeleteAlert>
          <ListItemText
            primary={`${item.model} ${item.year}`}
            secondary={`${item.brand}`}
            secondaryTypographyProps={{
              color: theme.palette.primary.contrastText,
            }}
            sx={{ pl: 2 }}
          />

          <ListItemText
            primary={"placeholder"}
            primaryTypographyProps={{
              color: "transparent",
            }}
            secondary={`${calculateDisplayedDistance(
              item.odometer,
              userDistanceUnit,
              item.distanceUnit || "km"
            )} ${userDistanceUnit}`}
            secondaryTypographyProps={{
              textAlign: "right",
              marginTop: "auto",
              color: theme.palette.primary.contrastText,
            }}
          ></ListItemText>
        </ListItemButton>
      </Box>
    );
  });

  return (
    <Fragment>
      <ListItemButton
        onClick={clickMotorcyclesListHandler}
        sx={{
          mx: 2,
          borderRadius: 3,
          "&.Mui-selected, &.Mui-selected:hover, &:hover": {
            backgroundColor: theme.palette.tertiary.main,
          },
        }}
      >
        <TwoWheelerIcon sx={{ pr: 1 }}></TwoWheelerIcon>
        <ListItemText primary="My Motorcycles" />
        {motorcyclesListOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={motorcyclesListOpen} timeout="auto" unmountOnExit>
        {props.loading ? (
          <Loading></Loading>
        ) : (
          <List component="div" disablePadding={true} sx={{ py: 0 }}>
            {listItems}
          </List>
        )}
      </Collapse>
    </Fragment>
  );
}

export default MotorcyclesList;

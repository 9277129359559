import {getTextfieldProps} from "../Styling/styling_utils";
import {useTheme} from "@mui/material";

export default function FormTextFieldProps() {
  const theme = useTheme();
  return {
    "& .MuiFormHelperText-root.Mui-error": {
      position: "absolute",
      top: "100%",
    },
    ...getTextfieldProps(theme),
  }
}
import { Divider, Typography } from "@mui/material";
import AddMotorcycleOptionButton from "./AddMotorcycleOptionButton";
import DownloadIcon from "@mui/icons-material/Download";
import CreateIcon from "@mui/icons-material/Create";

export default function AddMotorcycleOptions(props) {
  return (
    <>
      <AddMotorcycleOptionButton
        label={"Existing"}
        subtitle={
          "Add a motorcycle which already exists in our database with pre-configured reminders."
        }
        icon={<DownloadIcon sx={{ width: 28, height: 28 }}></DownloadIcon>}
        onClick={() => {
          props.setPage("existing");
        }}
      ></AddMotorcycleOptionButton>
      <Divider sx={{ p: 0, m: 0 }}>
        <Typography color="gray">or</Typography>
      </Divider>
      <AddMotorcycleOptionButton
        label={"New"}
        subtitle={
          "Add a new motorcycle which does not have any pre-configured reminders."
        }
        icon={<CreateIcon sx={{ width: 28, height: 28 }}></CreateIcon>}
        onClick={() => {
          props.setPage("new");
        }}
      ></AddMotorcycleOptionButton>
    </>
  );
}

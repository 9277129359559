import Button from "@mui/material/Button";
import { Box } from "@mui/material";

const payment_backend_url = process.env["REACT_APP_payment_backend_url"];

export default function SuccessDisplay({ sessionId }) {
  const action = `${payment_backend_url}` + "/create-portal-session";
  return (
    <section>
      <div className="product Box-root">
        <div className="description Box-root">
          <h3>Subscription to starter plan successful!</h3>
        </div>
      </div>
      <form action={action} method="POST">
        <input
          type="hidden"
          id="session-id"
          name="session_id"
          value={sessionId}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "20vw",
            gap: 2,
          }}
        >
          <Button
            type={"submit"}
            id="checkout-and-portal-button"
            variant={"contained"}
            size={"small"}
            fullWidth={false}
          >
            Manage your billing information
          </Button>
          <Button href="/" variant={"contained"}>
            Return Home
          </Button>
        </Box>
      </form>
    </section>
  );
}

/** @format */

import {Button, ListItemButton, ListItemText, useTheme} from "@mui/material";
import {Box} from "@mui/system";
import {Fragment} from "react";
import MenuWithSubmit from "../UI/MenuWithSubmit";
import AddEditScheduleTemplateForm from "./AddEditScheduleTemplateForm";
import ButtonWithDeleteAlert from "../UI/ButtonWithDeleteAlert";

function ScheduleTemplateListItem(props) {
  const theme = useTheme();
  const {
    distancePeriodValue,
    distancePeriodUnit,
    datePeriodValue,
    datePeriodUnit,
    name,
  } = props.item;
  let secondaryText = "";
  if (distancePeriodValue) {
    secondaryText =
      secondaryText + `every ${distancePeriodValue} ${distancePeriodUnit}`;
    if (datePeriodValue) {
      secondaryText = secondaryText + " or ";
    }
  }
  if (datePeriodValue) {
    secondaryText =
      secondaryText + `every ${datePeriodValue} ${datePeriodUnit}`;
  }
  return (
    <Fragment>
      <Box>
        <ListItemButton
          sx={{
            backgroundColor: theme.palette.tertiary.main,
            p: 2,
            m: 1,
            borderRadius: 3,
            "&.Mui-selected, &.Mui-selected:hover, &:hover": {
              borderColor: theme.palette.secondary.main,
              backgroundColor: theme.palette.secondary.main,
              boxShadow: 2,
            },
          }}
          selected={props.selected}
          onClick={props.onClick}
        >
          <ListItemText
            secondary={secondaryText}
            secondaryTypographyProps={{
              color: theme.palette.primary.contrastText,
            }}
          >
            {name}
          </ListItemText>
        </ListItemButton>
        {props.selected ? (
          <Box sx={{display: "flex", justifyContent: "flex-end", height: "40px", p: 1}}>
            <MenuWithSubmit transformOrigin={{horizontal: "left"}}
                            button={<Button variant={"contained"} color={"secondary"} sx={{
                              "&:hover": {
                                backgroundColor: theme.palette.secondary.hover,
                                transition: 0.7
                              }
                            }}>Edit entry</Button>}>
              <AddEditScheduleTemplateForm scheduleTemplate={props.item}
                                           onUpdate={props.onUpdate}></AddEditScheduleTemplateForm>
            </MenuWithSubmit>
            <ButtonWithDeleteAlert
              variant="contained"
              color="red"
              onDelete={props.onDelete}
              sx={{
                marginLeft: "auto", "&:hover": {boxSizing: "content-box", backgroundColor: theme.palette.red.hover},
              }}
              title="Delete schedule?"
              content={`Delete '${
                props.item.name
              }'?`}
            >
              Delete entry
            </ButtonWithDeleteAlert>
          </Box>
        ) : null}
      </Box>
    </Fragment>
  );
}

export default ScheduleTemplateListItem;

/** @format */

import {Checkbox} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import {createRef} from "react";
import InputField from "./InputField";

/**
 *
 * @typedef {Object} FormFieldObject
 * @property {Object} x test
 */

/**
 * @param {Object} params
 * @param {Array.<{name: string, label: string, type: string, defaultValue: string, defaultUnitValue: string, unitValues: Array.<string>, validationRules: Array.<func>>} params.components
 * @param {Object.<string, any>} params.inputProps
 * @param {Object.<string, any>} params.sx
 * @param {Object.<string, any>} params.boxSx sx parameters passed to the box  which will emcompass all the components
 * @param {bool} params.optional flag whether we should include a check marker which makes the whole box of components optional or not
 * @return {Array.<func>} renderInputs
 * @return {Array.<{name: string, label: string, value: string, valid: bool, errorMessage: string, touched: bool, ref: any, unitValue: string, validationRules: Array.<func>}>} componentsInfo
 */
export default function useFormFieldObject(params) {
  let { components, inputProps = {}, boxSx = {}, optional = false } = params;

  let renderInputs = null;
  let componentsInfo = [];

  if (components && components.length > 0) {
    renderInputs = components.map((component) => {
      const {
        name,
        label,
        type,
        defaultValue = "",
        defaultUnitValue = "",
        selectValues = [],
        unitValues = [],
        validationRules,
        sx = {},
      } = component;
      const ref = createRef();
      componentsInfo.push({
        name: name,
        label: label,
        value: defaultValue,
        selectValues: selectValues,
        valid: null,
        errorMessage: "",
        touched: false,
        ref: ref,
        unitValue: defaultUnitValue,
        validationRules: validationRules,
      });

      return (
        handleChange,
        handleBlur,
        value,
        isValid,
        error,
        key,
        unitValue,
        handleUnitChange
      ) => (
        <InputField
          key={name}
          name={name}
          type={type}
          label={label}
          isValid={isValid}
          value={value}
          selectValues={selectValues}
          handleBlur={handleBlur}
          handleChange={handleChange}
          errorMessage={error}
          sx={sx}
          ref={ref}
          inputProps={inputProps}
          unitValue={unitValue}
          handleUnitChange={handleUnitChange}
          unitSelectValues={unitValues}
        ></InputField>
      );
    });
  } else {
    // alert(`components should at least be of length 1.`);
  }

  if (optional) {
    const ref = createRef();
    const randomIdentifier = Math.random().toString(36).substr(2, 9).toString();
    const name = "optional" + randomIdentifier;
    const renderOptionalBox = (
      handleChange,
      handleBlur,
      value,
      isValid,
      error,
      key
    ) => {
      return (
        <FormControlLabel
          key={key}
          label={"include"}
          control={
            <Checkbox
              onChange={handleChange}
              checked={value}
              name={name}
              size={"small"}
            ></Checkbox>
          }
        ></FormControlLabel>
      );
    };

    renderInputs.push(renderOptionalBox);
    componentsInfo.push({
      name: name,
      label: "include",
      value: true,
      valid: true,
      errorMessage: "",
      touched: false,
      ref: ref,
      unitValue: "",
      validationRules: [],
    });
  }

  return {
    renderInputs,
    componentsInfo,
    sx: boxSx,
  };
}

export function getComponentValue(componentsInfo, name, attribute = "value") {
  // attribtue can be 'value' or 'unitValue'
  const component = componentsInfo.find((componentInfo) =>
    componentInfo.name.startsWith(name)
  );

  return component[attribute];
}

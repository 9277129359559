/** @format */

import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";

export const GetSubscriptionQuery = gql`
  query GetAllSubscriptionsForEmail($email: String!) {
    subscription(query: { email: $email }) {
      _id
      id
      cancelAtPeriodEnd
      customerId
      email
      status
      subscriptionEnd
      userId
    }
  }
`;

export function useSubscriptionForEmail(user) {
  const { data, loading, error } = useQuery(GetSubscriptionQuery, {
    variables: { email: `${user?._profile?.data?.email}` },
  });
  useEffect(() => {}, []);

  if (error) {
    throw new Error(`Failed to fetch subscription: ${error.message}`);
  }

  const subscription = data?.subscription ?? [];
  return {
    subscription,
    loading,
  };
}

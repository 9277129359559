/** @format */

import { gql, useQuery } from "@apollo/client";
import React, { useEffect } from "react";

export const GetUserQuery = gql`
  query GetUser($partition: String!) {
    user(query: { _partition: $partition }) {
      _id
      id
      name
      distanceUnit
      creationDate
    }
  }
`;

export function useUserForPartition(user) {
  const { data, loading, error } = useQuery(GetUserQuery, {
    variables: { partition: `user=${user?.id}` },
  });
  useEffect(() => {}, []);

  if (error) {
    throw new Error(`Failed to fetch users: ${error.message}`);
  }

  console.log(`data: ${data}`);
  const userData = data?.user ?? null;
  return {
    userData,
    loading,
  };
}

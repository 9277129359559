/** @format */
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {Button, Typography} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import {Box} from "@mui/system";
import {useEffect, useState} from "react";
import AddEditScheduleTemplateForm from "./AddEditScheduleTemplateForm";

export default function AddScheduleTemplate(props) {
  const [addScheduleOpen, setAddScheduleOpen] = useState(false);

  useEffect(() => {
    setAddScheduleOpen(false)
  }, [props.motorcycleTemplate.id])
  return (
    <Box>
      {!addScheduleOpen && (
        <Button
          elevation={0}
          variant="outlined"
          color="inherit"
          // backgroundColor="inherit"
          size="small"
          sx={{
            textTransform: "none",
            my: 3,
            border: 0,
            boxShadow: 0,
            p: 2,
            justifyContent: "flex-start",
            "&:hover": {
              color: "red",
            },
          }}
          onClick={() => {
            setAddScheduleOpen((prev) => !prev);
          }}
        >
          <AddCircleIcon fontSize="small" sx={{border: 0}}></AddCircleIcon>

          <Typography noWrap variant="p">
            Add Schedule
          </Typography>
        </Button>
      )}
      <Collapse
        in={addScheduleOpen}
        timeout={0}
        unmountOnExit
        sx={{
          my: 3,
        }}
      >
        <AddEditScheduleTemplateForm
          onClose={() => {
            setAddScheduleOpen(false);
          }}
          onSave={props.onAdd}
          motorcycleTemplate={props.motorcycleTemplate}
        ></AddEditScheduleTemplateForm>
      </Collapse>
    </Box>
  );
}

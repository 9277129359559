import { Controller, useForm } from "react-hook-form";
import { MenuItem, Select, TextField, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { Fragment } from "react";
import { calculateDisplayedDistance, dateToISOString } from "./utils";
import { Box } from "@mui/system";
import { useRealmApp } from "../../RealmApp";
import useTaskMutations from "../../graphql/useTaskMutations";
import {
  getMenuItemProps,
  getSelectProps,
  getTextfieldProps,
} from "../Styling/styling_utils";
import { isAfterToday } from "../Forms/validationFunctions";
import Typography from "@mui/material/Typography";

export default function AddFinishedTaskForm(props) {
  const motorcycle = props.motorcycle;
  const theme = useTheme();
  const app = useRealmApp();
  const userDistanceUnit = localStorage.getItem("distanceUnit");

  const { addTask } = useTaskMutations(app.currentUser);
  const today = new Date();
  const defaultDate = dateToISOString(today);

  const { handleSubmit, control, reset, register, errors } = useForm({
    defaultValues: {
      note: "",
      odometer: calculateDisplayedDistance(
        motorcycle.odometer,
        userDistanceUnit,
        motorcycle.distanceUnit || userDistanceUnit
      ).toString(),
      distanceUnit: userDistanceUnit,
      date: defaultDate,
    },
  });

  function onSubmit(data) {
    console.log(data);
    const newTask = {
      note: data.note,
      date: new Date(data.date),
      distanceUnit: data.distanceUnit,
      odometer: data.odometer,
      maintenanceScheduleId: props.maintenanceSchedule.id,
      id: Math.random().toString(36).substr(2, 9).toString(),
    };
    addTask(newTask);
    props.onClose();
  }

  return (
    <Fragment>
      <Typography>Adding entry...</Typography>
      <Controller
        name="note"
        control={control}
        // rules={{ required: "Password required" }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label={"Note"}
            multiline
            fullWidth={true}
            maxRows={5}
            value={value}
            sx={{
              my: 2,
              ...getTextfieldProps(theme),
            }}
            onChange={onChange}
            error={!!error}
            {...register("note")}
            helperText={error ? error.message : null}
          ></TextField>
        )}
      />
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
        <Controller
          name="odometer"
          control={control}
          rules={{
            required: { value: true, message: "Required" },
            min: { value: 0, message: "Must be positive" },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={"Odometer"}
              type={"number"}
              fullWidth={true}
              value={value}
              sx={{
                my: 1,
                "& .MuiFormHelperText-root.Mui-error": {
                  position: "absolute",
                  top: "100%",
                },
                ...getTextfieldProps(theme),
              }}
              onChange={onChange}
              error={!!error}
              {...register("odometer")}
              helperText={error ? error.message : null}
            ></TextField>
          )}
        />
        <Controller
          name="distanceUnit"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Select
              label={"label"}
              type={"number"}
              value={value}
              sx={{
                my: 1,
                ...getSelectProps(theme),
              }}
              MenuProps={{
                sx: {
                  ...getMenuItemProps(theme),
                },
              }}
              onChange={onChange}
              error={!!error}
              {...register("distanceUnit")}
            >
              <MenuItem value={"km"}>{"km"}</MenuItem>
              <MenuItem value={"mi"}>{"mi"}</MenuItem>
            </Select>
          )}
        />
      </Box>
      <Controller
        name="date"
        control={control}
        rules={{
          required: "Required",
          validate: {
            inPast: (value) => !isAfterToday(value) || "Must be in the past.",
          },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label={"Date"}
            type={"date"}
            value={value}
            sx={{
              my: 2,
              input: {
                color: theme.palette.primary.contrastText,
              },
              label: {
                color: theme.palette.primary.contrastText,
              },
              ...getTextfieldProps(theme),
              svg: { backgroundColor: "#fff" },
            }}
            onChange={onChange}
            inputProps={{ max: defaultDate }}
            error={!!error}
            {...register("date")}
            helperText={error ? error.message : null}
          ></TextField>
        )}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant={"contained"}
          color={"secondary"}
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <Button
          variant={"contained"}
          color={"orange"}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </Box>
    </Fragment>
  );
}

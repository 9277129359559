/** @format */

import {Divider, List, useTheme} from "@mui/material";
import NextMaintenanceTask from "./NextMaintenanceTask";
import {Fragment, useMemo} from "react";
import {useAllTasksForPartition} from "../../graphql/useTaskQueries";
import {useRealmApp} from "../../RealmApp";
import getTaskWithHighestOdometer, {calculateDaysToGo, calculateDistanceToGo,} from "./utils";

function MaintenanceSchedulesList(props) {
  const app = useRealmApp();
  const selectedMaintenanceSchedule = props.selectedMaintenanceSchedule;
  const motorcycle = props.motorcycle;
  const theme = useTheme();
  const {tasks, loading} = useAllTasksForPartition(app.currentUser);
  const userDistanceUnit = localStorage.getItem("distanceUnit");

  const maintenanceSchedulesEnriched = useMemo(() => {
    return props.maintenanceSchedules
      .map((maintenanceSchedule) => {
        const tasksForSchedule = tasks.filter(
          (task) => task.maintenanceScheduleId === maintenanceSchedule.id
        );
        const taskWithHighestOdometer = getTaskWithHighestOdometer(
          tasksForSchedule,
          userDistanceUnit
        );
        const distanceToGo = calculateDistanceToGo(
          taskWithHighestOdometer,
          maintenanceSchedule,
          motorcycle,
          userDistanceUnit
        );
        const daysToGo = calculateDaysToGo(
          maintenanceSchedule,
          taskWithHighestOdometer
        );
        return {
          maintenanceSchedule: maintenanceSchedule,
          distanceToGo: distanceToGo,
          daysToGo: daysToGo,
        };
      })
      .sort((a, b) => {
        if (a.distanceToGo === null) {
          return 1;
        }
        if (b.distanceToGo === null) {
          return -1;
        }
        if (a && b) {
          return a.distanceToGo < b.distanceToGo ? -1 : 1;
        }
      });
  }, [motorcycle, props.maintenanceSchedules, tasks, userDistanceUnit]);

  console.log(`PRINTING DISTANCE TO GO!`);
  maintenanceSchedulesEnriched.forEach((enriched) => {
    console.log(`distance to go: ${enriched.distanceToGo}`);
  });
  const nextMaintenanceTasks = maintenanceSchedulesEnriched.map(
    (maintenanceScheduleEnriched, index) => {
      const maintenanceSchedule =
        maintenanceScheduleEnriched.maintenanceSchedule;
      return (
        <Fragment key={index}>
          <NextMaintenanceTask
            maintenanceSchedule={maintenanceSchedule}
            motorcycle={motorcycle}
            daysToGo={maintenanceScheduleEnriched.daysToGo}
            distanceToGo={maintenanceScheduleEnriched.distanceToGo}
            onClick={props.onClick.bind(null, maintenanceSchedule)}
            selected={
              selectedMaintenanceSchedule?.id === maintenanceSchedule.id
            }
          ></NextMaintenanceTask>
          <Divider
            sx={{backgroundColor: theme.palette.secondary.dark}}
          ></Divider>
        </Fragment>
      );
    }
  );
  return (
    <List
      sx={{
        maxHeight: "calc(100% - 50px)", // MAGIC NUMBER
        p: 0,
        // height: 500,
        // border: 5,
        borderColor: "pink",
        boxSizing: "border-box",
        overflowY: "scroll",

        "&::-webkit-scrollbar": {
          width: 5,
          height: 30,
        },
        "&::-webkit-scrollbar-track": {
          // boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#3a3a3c",
          // outline: `1px solid slategrey`,
        },
      }}
    >
      {nextMaintenanceTasks}
    </List>
  );
}

export default MaintenanceSchedulesList;

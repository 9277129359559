/** @format */

import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";

export const GetAllMotorcyclesQuery = gql`
  query GetAllMotorcyclesForPartition($partition: String!) {
    motorcycles(query: { _partition: $partition }) {
      _id
      id
      brand
      model
      year
      odometer
      distanceUnit
    }
  }
`;

export function useAllMotorcyclesForPartition(user) {
  const { data, loading, error } = useQuery(GetAllMotorcyclesQuery, {
    variables: { partition: `user=${user.id}` },
  });
  useEffect(() => {}, []);

  if (error) {
    throw new Error(`Failed to fetch motorcycles: ${error.message}`);
  }

  const motorcycles = data?.motorcycles ?? [];
  return {
    motorcycles,
    loading,
  };
}

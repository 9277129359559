/** @format */

import { gql, useMutation } from "@apollo/client";
import { ObjectId } from "bson";

export default function useMotorcycleTemplateMutations(user) {
  return {
    addMotorcycleTemplate: useAddMotorcycleTemplate(user),
    updateMotorcycleTemplate: useUpdateMotorcycleTemplate(),
    deleteMotorcycleTemplate: useDeleteMotorcycleTemplate(),
  };
}

const AddMotorcycleTemplateMutation = gql`
  mutation AddMotorcycleTemplate(
    $motorcycleTemplate: MotorcycleTemplateInsertInput!
  ) {
    addedMotorcycleTemplate: insertOneMotorcycleTemplate(
      data: $motorcycleTemplate
    ) {
      _id
      id
      _partition
      brand
      year
      model
      creator
    }
  }
`;

const UpdateMotorcycleTemplateMutation = gql`
  mutation UpdateMotorcycleTemplate(
    $motorcycleTemplateId: String!
    $updates: MotorcycleTemplateUpdateInput!
  ) {
    updatedMotorcycleTemplate: updateOneMotorcycleTemplate(
      query: { id: $motorcycleTemplateId }
      set: $updates
    ) {
      _id
      id
      _partition
      brand
      year
      model
      creator
    }
  }
`;

const DeleteMotorcycleTemplateMutation = gql`
  mutation DeleteMotorcycleTemplate($motorcycleTemplateId: String!) {
    deletedMotorcycleTemplate: deleteOneMotorcycleTemplate(
      query: { id: $motorcycleTemplateId }
    ) {
      _id
      id
      _partition
      brand
      year
      model
      creator
    }
  }
`;

const MotorcycleTemplateFieldsFragment = gql`
  fragment MotorcycleTemplateFields on MotorcycleTemplate {
    _id
    id
    _partition
    brand
    year
    model
    creator
  }
`;

function useAddMotorcycleTemplate(user) {
  const [addMotorcycleTemplateMutation] = useMutation(
    AddMotorcycleTemplateMutation,
    {
      update: (cache, { data: { addedMotorcycleTemplate } }) => {
        cache.modify({
          fields: {
            motorcycleTemplates: (existingMotorcycleTemplates = []) => [
              ...existingMotorcycleTemplates,
              cache.writeFragment({
                data: addedMotorcycleTemplate,
                fragment: MotorcycleTemplateFieldsFragment,
              }),
            ],
          },
        });
      },
    }
  );

  const addMotorcycleTemplate = async (motorcycleTemplate) => {
    const { addedMotorcycleTemplate } = await addMotorcycleTemplateMutation({
      variables: {
        motorcycleTemplate: {
          _id: new ObjectId(),
          _partition: `creator=${user.id}`,
          id: motorcycleTemplate.id,
          brand: motorcycleTemplate.brand,
          year: motorcycleTemplate.year,
          model: motorcycleTemplate.model,
          creator: motorcycleTemplate.creator,
        },
      },
    });
    return addedMotorcycleTemplate;
  };
  return addMotorcycleTemplate;
}

function useUpdateMotorcycleTemplate() {
  const [updateMotorcycleTemplateMutation] = useMutation(
    UpdateMotorcycleTemplateMutation
  );
  const updateMotorcycleTemplate = async (motorcycleTemplate, updates) => {
    const { updatedMotorcycleTemplate } =
      await updateMotorcycleTemplateMutation({
        variables: { motorcycleTemplateId: motorcycleTemplate.id, updates },
      });
    return updatedMotorcycleTemplate;
  };
  return updateMotorcycleTemplate;
}

function useDeleteMotorcycleTemplate() {
  const [deleteMotorcycleTemplateMutation] = useMutation(
    DeleteMotorcycleTemplateMutation,
    {
      update: (cache, { data: { deletedMotorcycleTemplate } }) => {
        cache.modify({
          fields: {
            motorcycleTemplates(existingMotorcycleTemplateRefs, { readField }) {
              return existingMotorcycleTemplateRefs.filter(
                (motorcycleTemplateRef) =>
                  deletedMotorcycleTemplate.id !==
                  readField("id", motorcycleTemplateRef)
              );
            },
          },
        });
      },
      // refetchQueries: ["GetAllMotorcyclesForPartition"],
    }
  );
  const deleteMotorcycleTemplate = async (motorcycleTemplate) => {
    const { deletedMotorcycleTemplate } =
      await deleteMotorcycleTemplateMutation({
        variables: { motorcycleTemplateId: motorcycleTemplate.id },
      });
    return deletedMotorcycleTemplate;
  };
  return deleteMotorcycleTemplate;
}

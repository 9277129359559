/** @format */
import {IconButton, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTheme} from "@emotion/react";
import Box from "@mui/material/Box";
import FinishedTasks from "./FinishedTasks";
import {useRealmApp} from "../../RealmApp";
import {useAllTasksForPartition} from "../../graphql/useTaskQueries";
import useTaskMutations from "../../graphql/useTaskMutations";
import AddFinishedTask from "./AddFinishedTask";
import MaintenanceScheduleInfoView from "./MaintenanceScheduleInfoView";
import ButtonWithDeleteAlert from "../UI/ButtonWithDeleteAlert";
import DeleteIcon from "@mui/icons-material/Delete";
import {Close, Edit} from "@mui/icons-material";
import EditMaintenanceSchedule from "./EditMaintenanceSchedule";

function SelectedMaintenanceSchedule(props) {
  const app = useRealmApp();
  const {tasks} = useAllTasksForPartition(app.currentUser);
  const [editMode, setEditMode] = useState(false);
  const {updateTask, deleteTask} = useTaskMutations(app.currentUser);

  const maintenanceSchedule = props.maintenanceSchedule;
  const motorcycle = props.motorcycle;

  const theme = useTheme();

  function deleteMaintenanceSchedule() {
    props.onDelete(maintenanceSchedule.id);
  }

  function editMaintenanceSchedule(newMaintenanceSchedule) {
    props.onEdit(maintenanceSchedule, newMaintenanceSchedule);
  }

  useEffect(() => {
    setEditMode(false);
    console.log(`${motorcycle}`)
  }, [maintenanceSchedule]);

  let content = null;
  let finishedTasks = [];

  if (maintenanceSchedule) {
    if (tasks) {
      finishedTasks = tasks.filter((finishedTask) => {
        return finishedTask.maintenanceScheduleId === maintenanceSchedule.id;
      });
    }

    content = (
      <Box
        sx={{
          border: 1,
          p: 2,
          borderColor: theme.palette.secondary.main,
          backgroundColor: theme.palette.primary.main,
          width: "30vw",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: 5,
            height: 30,
          },
          "&::-webkit-scrollbar-track": {
            // boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#3a3a3c",
            // outline: `1px solid slategrey`,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" color={theme.palette.primary.contrastText} noWrap={true} sx={{maxWidth: "80%"}}>
            {maintenanceSchedule.name}
          </Typography>
          <Box sx={{display: "flex", flexGrow: "row"}}>
            <IconButton
              size="small"
              color={"secondary"}
              onClick={() => {
                setEditMode((prevEditMode) => {
                  return !prevEditMode;
                });
              }}
              sx={{marginLeft: "auto"}}
            >
              {editMode ? <Close></Close> : <Edit></Edit>}
            </IconButton>
            <ButtonWithDeleteAlert
              variant="outlined"
              color="red"
              type="icon"
              onDelete={deleteMaintenanceSchedule}
              sx={{
                border: 0,
                boxSizing: "border-box",
                "&:hover": {boxSizing: "content-box", backgroundColor: "rgba(158,44,44,0.43)"},
              }}
              title={`Delete entire reminder for ${maintenanceSchedule.name}?`}
              content={`Warning: deleting the entire reminder will also delete all the entries/history for this reminder.`}
            >
              <DeleteIcon></DeleteIcon>
            </ButtonWithDeleteAlert>
          </Box>
        </Box>
        <Box sx={{display: "flex", flexDirection: "row"}}>
          {editMode === false ? (
            <MaintenanceScheduleInfoView
              maintenanceSchedule={maintenanceSchedule}
            ></MaintenanceScheduleInfoView>
          ) : (
            <EditMaintenanceSchedule
              maintenanceSchedule={maintenanceSchedule}
              onEdit={editMaintenanceSchedule}
              onClose={() => setEditMode(false)}
            ></EditMaintenanceSchedule>
          )}
        </Box>
        <AddFinishedTask
          maintenanceSchedule={maintenanceSchedule}
          motorcycle={motorcycle}
        ></AddFinishedTask>

        <Box sx={{maxHeight: "30%"}}>
          <FinishedTasks
            finishedTasks={finishedTasks}
            maintenanceSchedule={maintenanceSchedule}
            onDelete={deleteTask}
            onUpdate={updateTask}
          ></FinishedTasks>
          <Box sx={{display: "flex", p: 1}}></Box>
        </Box>
      </Box>
    );
  }

  return content;
}

export default SelectedMaintenanceSchedule;

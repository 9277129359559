/** @format */

import {gql, useMutation} from "@apollo/client";
import {ObjectId} from "bson";

export default function useScheduleTemplateMutations(user) {
  return {
    addScheduleTemplate: useAddScheduleTemplate(user),
    updateScheduleTemplate: useUpdateScheduleTemplate(),
    deleteScheduleTemplate: useDeleteScheduleTemplate(),
  };
}

const AddScheduleTemplateMutation = gql`
    mutation AddMotorcycleTemplate(
        $scheduleTemplate: ScheduleTemplateInsertInput!
    ) {
        addedScheduleTemplate: insertOneScheduleTemplate(data: $scheduleTemplate) {
            _id
            id
            _partition
            datePeriodValue
            datePeriodUnit
            distancePeriodValue
            distancePeriodUnit
            name
            motorcycleTemplateId
        }
    }
`;

const UpdateScheduleTemplateMutation = gql`
    mutation UpdateScheduleTemplate(
        $scheduleTemplateId: String!
        $updates: ScheduleTemplateUpdateInput!
    ) {
        updatedScheduleTemplate: updateOneScheduleTemplate(
            query: { id: $scheduleTemplateId }
            set: $updates
        ) {
            _id
            id
            _partition
            datePeriodValue
            datePeriodUnit
            distancePeriodValue
            distancePeriodUnit
            name
            motorcycleTemplateId
        }
    }
`;

const DeleteScheduleTemplateMutation = gql`
    mutation DeleteScheduleTemplate($scheduleTemplateId: String!) {
        deletedScheduleTemplate: deleteOneScheduleTemplate(
            query: { id: $scheduleTemplateId }
        ) {
            _id
            id
            _partition
            datePeriodValue
            datePeriodUnit
            distancePeriodValue
            distancePeriodUnit
            name
            motorcycleTemplateId
        }
    }
`;

const ScheduleTemplateFieldsFragment = gql`
    fragment ScheduleTemplateFields on ScheduleTemplate {
        _id
        id
        _partition
        datePeriodValue
        datePeriodUnit
        distancePeriodValue
        distancePeriodUnit
        name
        motorcycleTemplateId
    }
`;

function useAddScheduleTemplate(user) {
  const [addScheduleTemplateMutation] = useMutation(
    AddScheduleTemplateMutation,
    {
      update: (cache, {data: {addedScheduleTemplate}}) => {
        cache.modify({
          fields: {
            scheduleTemplates: (existingScheduleTemplates = []) => [
              ...existingScheduleTemplates,
              cache.writeFragment({
                data: addedScheduleTemplate,
                fragment: ScheduleTemplateFieldsFragment,
              }),
            ],
          },
        });
      },
    }
  );

  const addScheduleTemplate = async (scheduleTemplate) => {
    const {addedScheduleTemplate} = await addScheduleTemplateMutation({
      variables: {
        scheduleTemplate: {
          _id: new ObjectId(),
          id: scheduleTemplate.id,
          _partition: `user=${user.id}`,
          datePeriodValue: scheduleTemplate.datePeriodValue,
          datePeriodUnit: scheduleTemplate.datePeriodUnit,
          distancePeriodValue: scheduleTemplate.distancePeriodValue,
          distancePeriodUnit: scheduleTemplate.distancePeriodUnit,
          name: scheduleTemplate.name,
          motorcycleTemplateId: scheduleTemplate.motorcycleTemplateId,
        },
      },
    });
    return addedScheduleTemplate;
  };
  return addScheduleTemplate;
}

function useUpdateScheduleTemplate() {
  const [updateScheduleTemplateMutation] = useMutation(
    UpdateScheduleTemplateMutation
  );
  const updateScheduleTemplate = async (scheduleTemplate, updates) => {
    const {updatedScheduleTemplate} = await updateScheduleTemplateMutation({
      variables: {scheduleTemplateId: scheduleTemplate.id, updates},
    });
    return updatedScheduleTemplate;
  };
  return updateScheduleTemplate;
}

function useDeleteScheduleTemplate() {
  const [deleteScheduleTemplateMutation] = useMutation(
    DeleteScheduleTemplateMutation,
    {
      update: (cache, {data: {deletedScheduleTemplate}}) => {
        cache.modify({
          fields: {
            scheduleTemplates(existingScheduleTemplateRefs, {readField}) {
              return existingScheduleTemplateRefs.filter(
                (scheduleTemplateRef) =>
                  deletedScheduleTemplate.id !==
                  readField("id", scheduleTemplateRef)
              );
            },
          },
        });
      },
      // refetchQueries: ["GetAllMotorcyclesForPartition"],
    }
  );
  const deleteScheduleTemplate = async (scheduleTemplate) => {
    const {deletedMotorcycleTemplate} = await deleteScheduleTemplateMutation({
      variables: {scheduleTemplateId: scheduleTemplate.id},
    });
    return deletedMotorcycleTemplate;
  };
  return deleteScheduleTemplate;
}

/** @format */
import {Checkbox} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import React, {useEffect, useRef, useState} from "react";
import useFormFields from "../../../hooks/useFormFields";
import DatePeriodTextField from "./DatePeriodTextField";
import StartingDateTextField from "./DateTextField";
import DistancePeriodTextField from "./DistancePeriodTextField";
import NameTextField from "./NameTextField";

export default function AddMaintenanceScheduleForm(props) {
  // name
  const nameRef = useRef();
  const [nameState, handleNameChange, validateName] =
    useFormFields("nonemptyString");
  // distance Period
  const distancePeriodRef = useRef();
  const [
    distancePeriodState,
    handleDistancePeriodChange,
    validateDistancePeriod,
  ] = useFormFields("positiveNumber");
  // distance Unit
  const [distanceUnit, setDistanceUnit] = React.useState(
    localStorage.getItem("distanceUnit")
  );

  function handleDistanceUnitChange(event) {
    setDistanceUnit(event.target.value);
  }

  // date period
  const datePeriodRef = useRef();
  const [datePeriodState, handleDatePeriodChange, validateDatePeriod] =
    useFormFields("positiveNumber");

  // date unit
  const [dateUnit, setDateUnit] = React.useState("days");

  function handleDateUnitChange(event) {
    setDateUnit(event.target.value);
  }

  // Starting date
  const startingDateRef = useRef();
  const [startingDateState, handleStartingDateChange, validateStartingDate] =
    useFormFields("date");

  // Form validity states
  // const [datesValid, setDatesValid] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);

  // Include Distance Period
  const [useDistanceBasedSchedule, setUseDistanceBasedSchedule] =
    useState(true);

  function toggleUseDistanceBasedSchedule(event) {
    setUseDistanceBasedSchedule(event.target.checked);
  }

  // Include Date Period
  const [useDateBasedSchedule, setUseDateBasedSchedule] = useState(true);

  function toggleUseDateBasedSchedule(event) {
    setUseDateBasedSchedule(event.target.checked);
  }

  useEffect(() => {
    let formIsValid = false;
    if (useDateBasedSchedule && useDistanceBasedSchedule) {
      formIsValid =
        nameState.isValid &&
        startingDateState.isValid &&
        datePeriodState.isValid &&
        distancePeriodState.isValid;
    }

    if (useDateBasedSchedule && !useDistanceBasedSchedule)
      formIsValid =
        nameState.isValid &&
        startingDateState.isValid &&
        datePeriodState.isValid;

    if (!useDateBasedSchedule && useDistanceBasedSchedule) {
      formIsValid = nameState.isValid && distancePeriodState.isValid;
    }

    if (!useDateBasedSchedule && !useDistanceBasedSchedule) {
      formIsValid = false;
    }

    setFormIsValid(formIsValid);
  }, [
    nameState.isValid,
    startingDateState.isValid,
    datePeriodState.isValid,
    distancePeriodState.isValid,
    useDateBasedSchedule,
    useDistanceBasedSchedule,
  ]);

  function onSubmit() {
    if (formIsValid) {
      const newDistancePeriod = useDistanceBasedSchedule
        ? +distancePeriodState.value
        : null;
      const newDatePeriod = useDateBasedSchedule
        ? +datePeriodState.value
        : null;
      const newStartingDate = useDateBasedSchedule
        ? new Date(startingDateState.value)
        : null;
      const newMaintenanceSchedule = {
        name: nameState.value,
        distancePeriodValue: newDistancePeriod,
        id: Math.random().toString(36).substr(2, 9).toString(),
        motorcycleId: props.motorcycle.id,
        finishedTasks: [],
        datePeriodValue: newDatePeriod,
        datePeriodUnit: dateUnit,
        distancePeriodUnit: distanceUnit,
        startingDate: newStartingDate,
      };

      props.onSubmit(newMaintenanceSchedule);
      props.onClose();
    } else if (!nameState.isValid) {
      validateName();
      nameRef.current.focus();
    } else if (!distancePeriodState.isValid && useDistanceBasedSchedule) {
      validateDistancePeriod();
      distancePeriodRef.current.focus();
    } else if (!datePeriodState.isValid && useDateBasedSchedule) {
      validateDatePeriod();
      datePeriodRef.current.focus();
    } else if (!startingDateState.isValid && useDateBasedSchedule) {
      validateStartingDate();
      startingDateRef.current.focus();
    }
  }

  // forms
  const nameForm = (
    <NameTextField
      error={nameState.isValid === false}
      ref={nameRef}
      value={nameState.value}
      onChange={handleNameChange}
      onValidate={validateName}
    ></NameTextField>
  );
  const distancePeriodForm = (
    <DistancePeriodTextField
      error={distancePeriodState.isValid === false && useDistanceBasedSchedule}
      ref={distancePeriodRef}
      value={distancePeriodState.value}
      label="Repeat every"
      onValidate={validateDistancePeriod}
      unit={distanceUnit}
      onValueChange={handleDistancePeriodChange}
      onUnitChange={handleDistanceUnitChange}
    ></DistancePeriodTextField>
  );
  const dateForm = (
    <DatePeriodTextField
      error={datePeriodState.isValid === false && useDateBasedSchedule}
      value={datePeriodState.value}
      ref={datePeriodRef}
      onValueChange={handleDatePeriodChange}
      label="Repeat every"
      onValidate={validateDatePeriod}
      unit={dateUnit}
      onUnitChange={handleDateUnitChange}
    ></DatePeriodTextField>
  );

  const startingDateForm = (
    <StartingDateTextField
      error={startingDateState.isValid === false && useDateBasedSchedule}
      onChange={handleStartingDateChange}
      onValidate={validateStartingDate}
      value={startingDateState.value}
      ref={startingDateRef}
    ></StartingDateTextField>
  );

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      m: 1,
      width: "30ch",
      gap: 2,
      p: 1,
      borderRadius: 2,
      backgroundColor: "#fff",
      // color: "#fff"
    }}>
      {nameForm}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          border: 1,
          borderRadius: 3,
          borderColor: "lightgray",
          p: 1,
        }}
      >
        {distancePeriodForm}
        <FormControlLabel
          control={
            <Checkbox
              onChange={toggleUseDistanceBasedSchedule}
              checked={useDistanceBasedSchedule}
              size="small"
            ></Checkbox>
          }
          label={"Include"}
        ></FormControlLabel>
      </Box>
      {/* <Divider>or</Divider> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          border: 1,
          borderRadius: 3,
          borderColor: "lightgray",
          p: 1,
        }}
      >
        {dateForm}
        {startingDateForm}
        <FormControlLabel
          control={
            <Checkbox
              onChange={toggleUseDateBasedSchedule}
              checked={useDateBasedSchedule}
              size="small"
            ></Checkbox>
          }
          label={"Include"}
        ></FormControlLabel>
      </Box>
      {!useDateBasedSchedule && !useDistanceBasedSchedule ? (
        <Typography color="red">{"Must include at least one."}</Typography>
      ) : null}
      <Box sx={{display: "flex", justifyContent: "flex-end", gap: 1, m: 1}}>
        <Button variant="outlined" size="small" onClick={props.onClose}>
          Cancel
        </Button>
        <Button variant="contained" size="small" onClick={onSubmit}>
          Save
        </Button>
      </Box>
    </Box>
  );
}

import { useEffect, useState } from "react";

import MobilePage from "./MobilePage";

export default function MobileDetector(props) {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  console.log(width);
  return isMobile ? <MobilePage></MobilePage> : props.children;
}

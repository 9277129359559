/** @format */

import { createTheme, ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import "./App.css";
import Sidebar from "./components/Sidebar/Sidebar";
import SignIn from "./components/SignIn/SignIn";
import RealmApolloProvider from "./graphql/RealmApolloProvider";
import { RealmAppProvider, useRealmApp } from "./RealmApp";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { PostPaymentPage } from "./components/Payment/PostPaymentPage";
import SubscriptionProvider from "./store/SubscriptionProvider";
import NotSubscribedModal from "./components/Payment/NotSubscribedModal";
import MobileDetector from "./components/Other/MobileDetector";
import PasswordResetEmail from "./components/SignIn/PasswordResetEmail";
import ResetPassword from "./components/SignIn/ResetPassword";

export const APP_ID = process.env["REACT_APP_realm_app_id"];

const { palette } = createTheme();
const theme = createTheme({
  typography: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        regular: {
          height: 64,
        },
      },
    },
  },
  primaryHoverFocus: {
    "&.Mui-selected, &.Mui-selected:hover, &:hover": {
      backgroundColor: "#2c2c2e",
    },
  },
  palette: {
    primary: { main: "#1b1b1d", contrastText: "#fff" },
    secondary: { main: "#636366", contrastText: "#fff", hover: "#7d7d80" },
    tertiary: { main: "#2c2c2e", contrastText: "#fff" },
    quaternary: { main: "#232325", contrastText: "#fff" },
    quinary: { main: "#8f8f94", contrastText: "#fff" },
    red: palette.augmentColor({
      color: {
        main: "#d05934",
        backgroundColor: "rgba(158,44,44,0.43)",
        hover: "#e76b44",
      },
    }),
    orange: { main: "#f58422", contrastText: "#fff" },
    white: { main: "#fff" },
    // gray: { main: "#", color: "#" },
    // text: {
    //   // primary: "#fff",
    //   secondary: "#fff",
    // },
  },
  primaryBackgroundColor: "white",
  secondaryBackgroundColor: "black",
});

const RequireLoggedInUser = ({ children }) => {
  // Only render children if there is a logged in user.
  const app = useRealmApp();
  return app.currentUser ? children : <SignIn />;
};

function App() {
  useEffect(() => {
    const distanceUnit = localStorage.getItem("distanceUnit");
    if (distanceUnit === null) {
      localStorage.setItem("distanceUnit", "km");
    }
  });

  return (
    <MobileDetector>
      <RealmAppProvider appId={APP_ID}>
        <RealmApolloProvider>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Switch>
                <Route path="/passwordreset">
                  <PasswordResetEmail></PasswordResetEmail>
                </Route>
                <Route path="/reset-password">
                  <ResetPassword></ResetPassword>
                </Route>
                <Route path="/paymentresult">
                  <PostPaymentPage></PostPaymentPage>
                </Route>
                <Route path="/">
                  <RequireLoggedInUser>
                    <SubscriptionProvider>
                      <Sidebar>
                        <NotSubscribedModal> </NotSubscribedModal>
                      </Sidebar>
                    </SubscriptionProvider>
                  </RequireLoggedInUser>
                </Route>
              </Switch>
            </BrowserRouter>
          </ThemeProvider>
        </RealmApolloProvider>
      </RealmAppProvider>
    </MobileDetector>
  );
}

export default App;

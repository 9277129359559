import {useTheme} from "@mui/material";
import {Box} from "@mui/system";

export default function FormCard(props) {
  const theme = useTheme();
  const sx = props.sx

  return (<Box backgroundColor={theme.palette.tertiary.main}
               color={theme.palette.secondary.contrastText}
               sx={{
                 p: 2,
                 borderRadius: 2,
                 display: "flex",
                 flexDirection: "column",
                 gap: 3,
                 ...sx
               }}>
    {props.children}</Box>)
}
/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import SettingsIcon from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { Button, useTheme } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import SettingsPage from "./SettingsPage";

export default function AccountMenu(props) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const username = props.username;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openSettings, setOpenSettings] = React.useState(false);

  function handleOpenSettings() {
    setOpenSettings(true);
  }

  function handleCloseSettings() {
    setOpenSettings(false);
    handleClose();
  }

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", pt: 2 }}>
        <Tooltip title="Account settings">
          <Button
            onClick={handleClick}
            endIcon={<ArrowDropDown></ArrowDropDown>}
            height={100}
            fullWidth
            sx={{
              ml: 0,
              border: 0,
              mx: 2,
              px: 2,
              justifyContent: "space-between",
              "&.Mui-selected, &.Mui-selected:hover, &:hover": {
                borderColor: theme.palette.secondary.main,
                backgroundColor: theme.palette.secondary.main,
                boxShadow: 2,
              },
              color: theme.palette.primary.contrastText,
            }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <SettingsIcon sx={{ pr: 1 }}></SettingsIcon>
            <Typography
              noWrap
              sx={{ fontSize: "1.0rem", textTransform: "lowercase" }}
            >
              {username}
            </Typography>
          </Button>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleOpenSettings}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <SettingsPage
          open={openSettings}
          onClose={handleCloseSettings}
          onUpdateUser={props.onUpdateUser}
          user={props.user}
        ></SettingsPage>
        <MenuItem onClick={props.onLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

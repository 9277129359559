/** @format */

import { forwardRef } from "react";
import { Box, TextField, Select, MenuItem } from "@mui/material";
import { getDefaultDate } from "../../../hooks/reducers";

const DateTextField = forwardRef((props, ref) => {
  const error = props.error;
  const value = props.value;
  const onChange = props.onChange;
  const onValidate = props.onValidate;

  return (
    <TextField
      error={error}
      helperText={error ? "Date is in the future (not yet supported)." : ""}
      label="Starting from"
      size="small"
      type="date"
      InputLabelProps={{ shrink: true }}
      onChange={onChange}
      onBlur={onValidate}
      value={value}
      inputProps={{ max: getDefaultDate() }}
      inputRef={ref}
    ></TextField>
  );
});

export default DateTextField;

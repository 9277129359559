/** @format */
import {ListItemButton, ListItemText, Typography, useTheme,} from "@mui/material";
import {Fragment} from "react";
import {useAllTasksForPartition} from "../../graphql/useTaskQueries";
import {useRealmApp} from "../../RealmApp";
import getTaskWithHighestOdometer, {calculateDisplayedDistance,} from "./utils";

function NextMaintenanceTask(props) {
  const app = useRealmApp();
  const theme = useTheme();
  const {tasks, loading} = useAllTasksForPartition(app.currentUser);
  const maintenanceSchedule = props.maintenanceSchedule;
  const motorcycle = props.motorcycle;
  const finishedTasks = tasks.filter((finishedTask) => {
    return finishedTask.maintenanceScheduleId === maintenanceSchedule.id;
  });
  const userDistanceUnit = localStorage.getItem("distanceUnit");

  const taskWithHighestOdometer = getTaskWithHighestOdometer(
    finishedTasks,
    userDistanceUnit
  );
  // const taskDistanceUnit = task
  const distanceToGo = props.distanceToGo;

  const daysToGo = props.daysToGo;
  let periodText = "";
  if (maintenanceSchedule.distancePeriodValue) {
    periodText =
      periodText +
      `every ${calculateDisplayedDistance(
        maintenanceSchedule.distancePeriodValue,
        userDistanceUnit,
        maintenanceSchedule.distancePeriodUnit
      )} ${userDistanceUnit}`;
    if (maintenanceSchedule.datePeriodValue) {
      periodText = periodText + " or ";
    }
  }

  if (maintenanceSchedule.datePeriodValue) {
    periodText =
      periodText +
      `every ${props.maintenanceSchedule.datePeriodValue} ${props.maintenanceSchedule.datePeriodUnit}`;
  }

  const distanceColor =
    distanceToGo && distanceToGo < 0
      ? theme.palette.red.main
      : theme.palette.primary.contrastText;
  const daysColor =
    daysToGo && daysToGo < 0
      ? theme.palette.red.main
      : theme.palette.primary.contrastText;

  let toGoText = "";
  toGoText = (
    <Fragment>
      <Typography variant="p" color={distanceColor}>
        {`${
          maintenanceSchedule.distancePeriodValue
            ? `${calculateDisplayedDistance(
              distanceToGo,
              userDistanceUnit,
              userDistanceUnit
            ).toFixed(2)} ${userDistanceUnit}`
            : ""
        }`}
      </Typography>
      {daysToGo ?
        <Fragment>
          <Typography variant="p" color={daysColor}>
            {`${
              maintenanceSchedule.distancePeriodValue &&
              maintenanceSchedule.datePeriodValue
                ? " or "
                : ""
            }`}
          </Typography>
          <Typography variant="p" color={daysColor}>
            {`
        ${maintenanceSchedule.datePeriodValue ? `${daysToGo} days` : ""}
        `}
          </Typography>
        </Fragment> : null
      }
    </Fragment>
  );

  const kmText =
    (distanceToGo && distanceToGo < 0) || (daysToGo && daysToGo < 0) ? (
      <Typography
        variant="subtitle2"
        color={theme.palette.red.main}
        textAlign="right"
      >
        remaining
      </Typography>
    ) : (
      <Typography variant="subtitle2" textAlign="right">
        remaining
      </Typography>
    );
  return (
    <ListItemButton
      sx={{
        height: 70,
        // width: "100%",
        justifyContent: "space-between",
        display: "flex",
        "&.Mui-selected, &.Mui-selected:hover, &:hover": {
          backgroundColor: theme.palette.tertiary.main,
        },
        ".MuiListItemButton-divider": {
          backgroundColor: "white",
        },
        // borderBottom: 1,
      }}
      onClick={props.onClick}
      selected={props.selected}
    >
      <ListItemText
        primary={maintenanceSchedule.name}
        style={{whiteSpace: "normal"}}
        primaryTypographyProps={{noWrap: true, sx: {maxWidth: 300}}}
        secondary={periodText}
        secondaryTypographyProps={{color: theme.palette.primary.contrastText}}
        sx={{border: 0, flexGrow: 0}}
      ></ListItemText>

      <ListItemText
        primary={toGoText}
        primaryTypographyProps={{textAlign: "right"}}
        secondary={kmText}
        secondaryTypographyProps={{
          textAlign: "right",
        }}
        sx={{border: 0, flexGrow: 0}}
      ></ListItemText>
    </ListItemButton>
  );
}

export default NextMaintenanceTask;

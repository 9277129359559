/** @format */

import * as React from "react";
import { cloneElement, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

const style = {
  position: "absolute",
  display: "inline-block",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  // bgcolor: "primary.main",
  // border: "2px solid #000",
  border: "primary",
  borderRadius: "5px",
  boxShadow: 24,
  textAlign: "center",
  p: 4,
  wordWrap: "break-word",
  width: 400,
  height: 600,
};

export default function ButtonWithModal(props) {
  const button = props.button;
  const disableBackdropClick = props.disableBackdropClick
    ? props.disableBackdropClick
    : false;
  const sx = { ...style, ...props.sx };

  const [showModal, setShowModal] = useState(false);

  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  return (
    <div>
      {cloneElement(button, { onClick: openModal })}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showModal}
        onClose={disableBackdropClick ? null : closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200,
        }}
      >
        <Fade in={showModal}>
          <Box sx={sx}>
            {cloneElement(props.children, {
              onClose: closeModal,
            })}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

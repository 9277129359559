/** @format */

import {AppBar, Toolbar, Typography, useTheme} from "@mui/material";
import {Box} from "@mui/system";
import {useState} from "react";
import useMotorcycleTemplateMutations from "../../graphql/useMotorcycleTemplateMutations";
import {useAllMotorcycleTemplatesForPartition} from "../../graphql/useMotorcycleTemplateQueries";
import {useRealmApp} from "../../RealmApp";
import MenuWithSubmit from "../UI/MenuWithSubmit";
import SidebarPageBox from "../Sidebar/SidebarPageBox";
import MotorcycleTemplate from "./MotorcycleTemplate";
import MotorcycleTemplatesList from "./MotorcycleTemplatesList";
import AddEditMotorcycleTemplateForm from "./AddEditMotorcycleTemplateForm";

export default function AdminPage(props) {
  const app = useRealmApp();
  const theme = useTheme();

  const {motorcycleTemplates, loading} =
    useAllMotorcycleTemplatesForPartition(app.currentUser);

  const {
    addMotorcycleTemplate,
    deleteMotorcycleTemplate,
    updateMotorcycleTemplate,
  } = useMotorcycleTemplateMutations(app.currentUser);

  const [selectedIndex, setSelectedIndex] = useState(null);

  function selectHandler(motorcycleTemplate) {
    const index = motorcycleTemplates.findIndex((item) => {
      return item.id === motorcycleTemplate.id;
    });
    setSelectedIndex(index);
  }

  return (
    <SidebarPageBox>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Typography>Motorcycles Database</Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          position: "sticky",
          flexGrow: 1,
          minHeight: 0, // https://stackoverflow.com/questions/36230944/prevent-flex-items-from-overflowing-a-container
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <AppBar
            elevation={0}
            sx={{ml: 0, mt: 0, position: "sticky"}}
            color="tertiary"
          >
            <Toolbar variant="dense" sx={{
              borderTop: 1,
              borderBottom: 1,
              borderColor: theme.palette.secondary.main,
              justifyContent: "space-between",
            }}>
              <MenuWithSubmit label={"Add Motorcycle Template"}>
                <AddEditMotorcycleTemplateForm
                  user={app.currentUser}
                  onAdd={addMotorcycleTemplate}
                ></AddEditMotorcycleTemplateForm>
              </MenuWithSubmit>
            </Toolbar>
          </AppBar>
          <MotorcycleTemplatesList
            motorcycleTemplates={motorcycleTemplates}
            onClick={selectHandler}
            selectedIndex={selectedIndex}
          ></MotorcycleTemplatesList>
        </Box>
        <MotorcycleTemplate
          template={motorcycleTemplates[selectedIndex]}
          onDelete={deleteMotorcycleTemplate}
          onEdit={updateMotorcycleTemplate}
          user={app.currentUser}
        ></MotorcycleTemplate>
      </Box>
    </SidebarPageBox>
  );
}

/** @format */

import React from "react";
import SelectedMotorcycle from "../SelectedMotorcycle/SelectedMotorcycle";
import AdminPage from "../AdminPage/AdminPage";

function SidebarPage(props) {
  const motorcycles = props.motorcycles;
  const selectedItemsState = props.selectedItemsState;
  const selectedMotorcycle =
    motorcycles[
      motorcycles
        .map((e) => {
          return e.id;
        })
        .indexOf(selectedItemsState.selectedMotorcycleId)
    ];

  let content = null;
  if (selectedMotorcycle) {
    // Motorcycle is selected, therefore we should display a motorcycle page
    content = (
      <SelectedMotorcycle
        motorcycle={selectedMotorcycle}
      ></SelectedMotorcycle>
    );
  }
  if (selectedItemsState.selectedSidebarItem === "motorcycles_database") {
    content = <AdminPage></AdminPage>;
  }
  if (selectedItemsState.selectedSidebarItem === "home") {
  }
  if (selectedItemsState.selectedSidebarItem === "history") {
  }
  return content;
}

export default SidebarPage;

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { useSubscription } from "../../store/SubscriptionProvider";
import { AppBar, Divider, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useRealmApp } from "../../RealmApp";
import SubscriptionInfo from "./SubscriptionInfo";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const style = {
  position: "absolute",
  display: "inline-block",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  // bgcolor: "primary.main",
  // border: "2px solid #000",
  // border: "primary",
  borderRadius: "10px",
  boxShadow: 24,
  textAlign: "center",
  p: 0,
  wordWrap: "break-word",
  width: 400,
  height: 600,
};

export default function NotSubscribedModal(props) {
  const subscription = useSubscription();
  const app = useRealmApp();
  const user = app.currentUser;
  const subscriptionEnd = subscription.subscriptionEnd;
  const loading = subscription.loading;
  let content = null;

  const [showModal, setShowModal] = useState(false);

  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  useEffect(() => {
    if (
      loading === false &&
      (subscriptionEnd === null ||
        subscriptionEnd === undefined ||
        new Date(subscriptionEnd) < new Date())
    ) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [subscriptionEnd, loading]);

  console.log(`subbbb: ${subscriptionEnd}`);
  content = (
    <Modal
      open={showModal}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 200,
      }}
    >
      <Fade in={showModal}>
        <Box sx={{ ...style }}>
          <Box>
            <AppBar
              elevation={0}
              sx={{
                backgroundColor: "transparent",
                p: 2,
                position: "relative",
              }}
            >
              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{}}>
                  <IconButton onClick={props.onClose} disabled={true}>
                    <CloseIcon sx={{ color: "transparent" }}></CloseIcon>
                  </IconButton>
                </Box>
                <Typography color={"black"} variant={"h6"}>
                  Subscribe
                </Typography>
                <Box sx={{}}>
                  <IconButton onClick={closeModal}>
                    <CloseIcon sx={{ color: "black" }}></CloseIcon>
                  </IconButton>
                </Box>
              </Box>
            </AppBar>
            <Divider sx={{ p: 0, m: 0 }}></Divider>
            <Box sx={{ p: 2, textAlign: "left" }}>
              <Typography>
                You're currently not subscribed or your trial will expire soon.
                Without a subscription, you're only able to have a single
                motorcycle and a limited amount of reminders!
              </Typography>
            </Box>

            <SubscriptionInfo user={user}></SubscriptionInfo>
            <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                <Typography>Subscribing enables you to have</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                <CheckCircleOutlineIcon
                  color={"success"}
                ></CheckCircleOutlineIcon>
                <Typography>Unlimited amount of motorcycles</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                <CheckCircleOutlineIcon
                  color={"success"}
                ></CheckCircleOutlineIcon>
                <Typography>
                  Unlimited amount of reminders per motorcycle
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
  return content;
}

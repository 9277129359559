/** @format */

import {List, ListSubheader, useTheme} from "@mui/material";
import {useEffect, useState} from "react";
import ScheduleTemplateListItem from "./ScheduleTemplateListItem";

function ScheduleTemplatesList(props) {
  const [selectedListItemId, setSelectedListItem] = useState(null);
  const theme = useTheme();

  function selectListItemHandler(id) {
    setSelectedListItem(id);
  }

  const motorcycleTemplate = props.motorcycleTemplate;

  useEffect(() => {
    setSelectedListItem(null);
  }, [motorcycleTemplate]);

  const templates = props.items;

  const elements = templates.map((template, index) => {
    return (
      <ScheduleTemplateListItem
        key={index}
        item={template}
        selected={template.id === selectedListItemId}
        onClick={selectListItemHandler.bind(null, template.id)}
        onDelete={props.onDelete.bind(null, template)}
        onUpdate={props.onUpdate}
      ></ScheduleTemplateListItem>
    );
  });
  return (
    <List
      subheader={
        <ListSubheader
          component="div"
          sx={{
            color: theme.palette.primary.contrastText,
            backgroundColor: "transparent",
          }}
        >
          Schedules
        </ListSubheader>
      }
      sx={{p: 1}}
    >
      {elements}
    </List>
  );
}

export default ScheduleTemplatesList;

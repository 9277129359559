/** @format */

import { gql, useQuery } from "@apollo/client";
import React, { useEffect } from "react";

export const GetAllSchedulesQuery = gql`
  query GetAllSchedulesForPartition($partition: String!) {
    schedules(query: { _partition: $partition }) {
      _id
      _partition
      id
      name
      distancePeriodValue
      motorcycleId
      datePeriodValue
      datePeriodUnit
      distancePeriodUnit
      startingDate
    }
  }
`;

export function useAllSchedulesForPartition(user) {
  const { data, loading, error, refetch } = useQuery(GetAllSchedulesQuery, {
    variables: { partition: `user=${user.id}` },
  });
  useEffect(() => {}, []);

  if (error) {
    throw new Error(`Failed to fetch schedules: ${error.message}`);
  }

  const allMaintenanceSchedules = data?.schedules ?? [];
  return {
    allMaintenanceSchedules,
    loading,
    refetch,
  };
}

/** @format */

import { gql, useMutation } from "@apollo/client";
import { ObjectId } from "bson";
import { GetAllSchedulesQuery } from "./useScheduleQueries";
import React from "react";

export default function useScheduleMutations(user) {
  return {
    addMaintenanceSchedule: useAddSchedule(user),
    addMaintenanceSchedules: useAddSchedules(user),
    updateMaintenanceSchedule: useUpdateSchedule(),
    deleteMaintenanceSchedule: useDeleteSchedule(),
  };
}

const AddScheduleMutation = gql`
  mutation AddSchedule($schedule: ScheduleInsertInput!) {
    addedSchedule: insertOneSchedule(data: $schedule) {
      _id
      _partition
      id
      name
      distancePeriodValue
      motorcycleId
      datePeriodValue
      datePeriodUnit
      distancePeriodUnit
      startingDate
    }
  }
`;

const AddSchedulesMutation = gql`
  mutation AddSchedules($schedules: [ScheduleInsertInput!]!) {
    addedSchedules: insertManySchedules(data: $schedules) {
      insertedIds
    }
  }
`;

const UpdateScheduleMutation = gql`
  mutation UpdateSchedule(
    $scheduleId: String!
    $updates: ScheduleUpdateInput!
  ) {
    updatedSchedule: updateOneSchedule(
      query: { id: $scheduleId }
      set: $updates
    ) {
      _id
      _partition
      id
      name
      distancePeriodValue
      motorcycleId
      datePeriodValue
      datePeriodUnit
      distancePeriodUnit
      startingDate
    }
  }
`;

const DeleteScheduleMutation = gql`
  mutation DeleteSchedule($scheduleId: String!) {
    deletedSchedule: deleteOneSchedule(query: { id: $scheduleId }) {
      _id
      _partition
      id
      name
      distancePeriodValue
      motorcycleId
      datePeriodValue
      datePeriodUnit
      distancePeriodUnit
      startingDate
    }
  }
`;

const ScheduleFieldsFragment = gql`
  fragment ScheduleFields on Schedule {
    _id
    _partition
    id
    name
    distancePeriodValue
    motorcycleId
    datePeriodValue
    datePeriodUnit
    distancePeriodUnit
    startingDate
  }
`;

function useAddSchedule(user) {
  const [addScheduleMutation] = useMutation(AddScheduleMutation, {
    update: (cache, { data: { addedSchedule } }) => {
      cache.modify({
        fields: {
          schedules: (existingSchedules = []) => [
            ...existingSchedules,
            cache.writeFragment({
              data: addedSchedule,
              fragment: ScheduleFieldsFragment,
            }),
          ],
        },
      });
    },
  });

  const addSchedule = async (schedule) => {
    const { addedSchedule } = await addScheduleMutation({
      variables: {
        schedule: {
          _id: new ObjectId(),
          _partition: `user=${user.id}`,
          id: schedule.id,
          name: schedule.name,
          distancePeriodValue: schedule.distancePeriodValue,
          motorcycleId: schedule.motorcycleId,
          datePeriodValue: schedule.datePeriodValue,
          datePeriodUnit: schedule.datePeriodUnit,
          distancePeriodUnit: schedule.distancePeriodUnit,
          startingDate: schedule.startingDate,
        },
      },
    });
    return addedSchedule;
  };
  return addSchedule;
}

function useAddSchedules(user) {
  const [addSchedulesMutation] = useMutation(AddSchedulesMutation, {
    refetchQueries: () => [GetAllSchedulesQuery],
  });

  const addSchedules = async (schedules) => {
    const { addedSchedule } = await addSchedulesMutation({
      variables: {
        schedules: schedules.map((schedule) => {
          return {
            _id: new ObjectId(),
            _partition: `user=${user.id}`,
            id: schedule.id,
            name: schedule.name,
            distancePeriodValue: schedule.distancePeriodValue,
            motorcycleId: schedule.motorcycleId,
            datePeriodValue: schedule.datePeriodValue,
            datePeriodUnit: schedule.datePeriodUnit,
            distancePeriodUnit: schedule.distancePeriodUnit,
            startingDate: schedule.startingDate,
          };
        }),
      },
    });
    return addedSchedule;
  };
  return addSchedules;
}

function useUpdateSchedule() {
  const [updateScheduleMutation] = useMutation(UpdateScheduleMutation);
  const updateSchedule = async (schedule, updates) => {
    const { updatedSchedule } = await updateScheduleMutation({
      variables: { scheduleId: schedule.id, updates },
    });
    return updatedSchedule;
  };
  return updateSchedule;
}

function useDeleteSchedule() {
  const [deleteScheduleMutation] = useMutation(DeleteScheduleMutation, {
    update: (cache, { data: { deletedSchedule } }) => {
      cache.modify({
        fields: {
          schedules(existingScheduleRefs, { readField }) {
            return existingScheduleRefs.filter(
              (scheduleRef) =>
                deletedSchedule.id !== readField("id", scheduleRef)
            );
          },
        },
      });
    },
    // refetchQueries: ["GetAllMotorcyclesForPartition"],
  });
  const deleteSchedule = async (scheduleId) => {
    const { deletedSchedule } = await deleteScheduleMutation({
      variables: { scheduleId: scheduleId },
    });
    return deletedSchedule;
  };
  return deleteSchedule;
}

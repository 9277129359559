import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import * as React from "react";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useRealmApp } from "../../RealmApp";
import { Controller, useForm } from "react-hook-form";
import { useTheme } from "@mui/system";
import { FormHelperText } from "@mui/material";

export default function ResetPassword(props) {
  const app = useRealmApp();
  const theme = useTheme();

  const [token, setToken] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const {
    handleSubmit,
    control,
    reset,
    register,
    errors,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      password: "",
      confirmedPassword: "",
    },
  });

  useEffect(() => {
    // in this location, this never gets called because this component is closed after visiting the checkout form
    const query = new URLSearchParams(window.location.search);
    if (query.get("token")) {
      setToken(query.get("token"));
    }
    if (query.get("tokenId")) {
      setTokenId(query.get("tokenId"));
    }

    console.log(`query: ${query}`);
    console.log(`token: ${token}`);
  }, [token, tokenId]);

  async function handleResetPassword(data) {
    try {
      // Change Password
      await app.emailPasswordAuth.resetPassword({
        password: data.password,
        token,
        tokenId,
      });
      setSuccess(true);
      setValue("password", "");
      setValue("confirmedPassword", "");
    } catch (err) {
      //
      setSuccess(false);
      console.log(`error: ${err}`);
      console.log(`${err.message}`);
      setErrorMessage(err.message);
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          // top: "20vh",
          position: "relative",
          justifyContent: "center",
          minHeight: "60vh",
        }}
      >
        <Typography variant={"h6"} color={"white"}>
          Reset Password
        </Typography>
        <Controller
          name="password"
          control={control}
          rules={{
            validate: {
              required: (value) => {
                if (value === "") {
                  return "Required";
                } else return true;
              },
              minLen: (value) => {
                if (value.length < 6) {
                  return "Must have at least 6 characters";
                } else return true;
              },
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={"Password"}
              fullWidth={true}
              value={value}
              type="password"
              sx={{
                my: 2,
              }}
              onChange={onChange}
              error={!!error}
              {...register("password")}
              helperText={error ? error.message : null}
            ></TextField>
          )}
        />
        <Controller
          name="confirmedPassword"
          control={control}
          rules={{
            validate: {
              required: (value) => {
                if (value === "") {
                  return "Required";
                } else return true;
              },
              minLen: (value) => {
                if (value.length < 6) {
                  return "Must have at least 6 characters";
                } else return true;
              },
              notEqual: (value) => {
                if (getValues("password") !== value) {
                  return "Passwords are not equal!";
                } else return true;
              },
            },
          }}
          // rules={{ required: "Password required" }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={"Confirm Password"}
              fullWidth={true}
              value={value}
              sx={{
                my: 2,
              }}
              onChange={onChange}
              type={"password"}
              error={!!error}
              {...register("confirmedPassword")}
              helperText={error ? error.message : null}
            ></TextField>
          )}
        />
        {success ? (
          <Button
            href={"/"}
            variant={"contained"}
            sx={{ mt: 3, mb: 2 }}
            fullWidth
          >
            Back to login
          </Button>
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleSubmit(handleResetPassword)}
          >
            Change Password
          </Button>
        )}
        <Typography>
          {success ? "Successfully changed password" : null}
        </Typography>
        <FormHelperText error={true}>
          {errorMessage ? `Error occurred: ${errorMessage}` : null}
        </FormHelperText>
      </Grid>
    </Container>
  );
}

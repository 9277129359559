/** @format */

import {gql, useMutation} from "@apollo/client";
import {ObjectId} from "bson";

import React from "react";

export default function useMotorcycleMutations(user) {
  return {
    addMotorcycle: useAddMotorcycle(user),
    updateMotorcycle: useUpdateMotorcycle(),
    deleteMotorcycle: useDeleteMotorcycle(),
  };
}

const AddMotorcycleMutation = gql`
    mutation AddMotorcycle($motorcycle: MotorcycleInsertInput!) {
        addedMotorcycle: insertOneMotorcycle(data: $motorcycle) {
            _id
            _partition
            id
            brand
            year
            model
            odometer
            distanceUnit
        }
    }
`;

const UpdateMotorcycleMutation = gql`
    mutation UpdateMotorcycle(
        $motorcycleId: String!
        $updates: MotorcycleUpdateInput!
    ) {
        updatedMotorcycle: updateOneMotorcycle(
            query: { id: $motorcycleId }
            set: $updates
        ) {
            _id
            _partition
            id
            brand
            year
            model
            odometer
            distanceUnit
        }
    }
`;

const DeleteMotorcycleMutation = gql`
    mutation DeleteMotorcycle($motorcycleId: String!) {
        deletedMotorcycle: deleteOneMotorcycle(query: { id: $motorcycleId }) {
            _id
            _partition
            id
            brand
            year
            model
            odometer
            distanceUnit
        }
    }
`;

const MotorcycleFieldsFragment = gql`
    fragment MotorcycleFields on Motorcycle {
        _id
        _partition
        id
        brand
        year
        model
        odometer
        distanceUnit
    }
`;

function useAddMotorcycle(user) {
  const [addMotorcycleMutation] = useMutation(AddMotorcycleMutation, {
    update: (cache, {data: {addedMotorcycle}}) => {
      cache.modify({
        fields: {
          motorcycles: (existingMotorcycles = []) => [
            ...existingMotorcycles,
            cache.writeFragment({
              data: addedMotorcycle,
              fragment: MotorcycleFieldsFragment,
            }),
          ],
        },
      });
    },
  });

  const addMotorcycle = async (motorcycle) => {
    const {addedMotorcycle} = await addMotorcycleMutation({
      variables: {
        motorcycle: {
          _id: new ObjectId(),
          _partition: `user=${user.id}`,
          id: motorcycle.id,
          brand: motorcycle.brand,
          year: motorcycle.year,
          model: motorcycle.model,
          odometer: motorcycle.odometer,
          distanceUnit: motorcycle.distanceUnit,
        },
      },
    });
    return addedMotorcycle;
  };
  return addMotorcycle;
}

function useUpdateMotorcycle() {
  const [updateMotorcycleMutation] = useMutation(UpdateMotorcycleMutation);
  const updateMotorcycle = async (motorcycle, updates) => {
    const {updatedMotorcycle} = await updateMotorcycleMutation({
      variables: {motorcycleId: motorcycle.id, updates},
    });
    return updatedMotorcycle;
  };
  return updateMotorcycle;
}

function useDeleteMotorcycle() {
  const [deleteMotorcycleMutation] = useMutation(DeleteMotorcycleMutation, {
    update: (cache, {data: {deletedMotorcycle}}) => {
      cache.modify({
        fields: {
          motorcycles(existingMotorcycleRefs, {readField}) {
            return existingMotorcycleRefs.filter(
              (motorcycleRef) =>
                deletedMotorcycle.id !== readField("id", motorcycleRef)
            );
          },
        },
      });
    },
    // refetchQueries: ["GetAllMotorcyclesForPartition"],
  });
  const deleteMotorcycle = async (motorcycle) => {
    const {deletedMotorcycle} = await deleteMotorcycleMutation({
      variables: {motorcycleId: motorcycle.id},
    });
    return deletedMotorcycle;
  };
  return deleteMotorcycle;
}

import {TextField, useTheme} from "@mui/material";
import {useRealmApp} from "../../RealmApp";
import {Controller, useForm} from "react-hook-form";
import {Box} from "@mui/system";
import Button from "@mui/material/Button";
import FormCard from "../UI/FormCard";
import FormTextFieldProps from "../UI/FormTextFieldProps";
import {isAdmin} from "../permissions";

export default function AddEditMotorcycleTemplateForm(props) {
  const theme = useTheme();
  const app = useRealmApp();
  const user = app.currentUser

  const motorcycleTemplate = props.motorcycleTemplate

  const {handleSubmit, control, reset, register, errors} = useForm({
    defaultValues: {
      // brand: motorcycleTemplate ? motorcycleTemplate.brand : "",
      brand: motorcycleTemplate?.brand || "",
      model: motorcycleTemplate?.model || "",
      year: motorcycleTemplate?.year || ""
    },
  });

  const onUpdate = props.onUpdate
  const onAdd = props.onAdd

  function onSubmit(data) {
    console.log(data);
    if (onAdd) {
      const newMotorcycleTemplate = {
        brand: data.brand,
        model: data.model,
        year: +data.year,
        id: Math.random().toString(36).substr(2, 9).toString(),
        creator: isAdmin(user) ? "admin" : user.id.toString(),
      };
      onAdd(newMotorcycleTemplate)
    } else if (onUpdate) {
      const newMotorcycleTemplate = {
        brand: data.brand,
        model: data.model,
        year: +data.year,
      };
      onUpdate(motorcycleTemplate, newMotorcycleTemplate)
      console.log("Updating motorcycle template!")
    }

    props.onClose();
  }

  return (
    <FormCard sx={{...props.sx}}>
      <Controller
        name="brand"
        control={control}
        rules={{
          required: {value: true, message: "Required"},
        }}
        // rules={{ required: "Password required" }}
        render={({field: {onChange, value}, fieldState: {error}}) => (
          <TextField
            label={"Brand"}
            fullWidth={true}
            value={value}
            sx={FormTextFieldProps()}
            onChange={onChange}
            error={!!error}
            {...register("brand")}
            helperText={error ? error.message : null}
          ></TextField>
        )}
      />
      <Controller
        name="model"
        control={control}
        rules={{
          required: {value: true, message: "Required"},
        }}
        render={({field: {onChange, value}, fieldState: {error}}) => (
          <TextField
            label={"Model"}
            fullWidth={true}
            value={value}
            sx={FormTextFieldProps()}
            onChange={onChange}
            error={!!error}
            {...register("model")}
            helperText={error ? error.message : null}
          ></TextField>
        )}
      />
      <Controller
        name="year"
        control={control}
        rules={{
          required: {value: true, message: "Required"},
          min: {value: 0, message: "Must be positive"},
        }}
        render={({field: {onChange, value}, fieldState: {error}}) => (
          <TextField
            label={"Year"}
            type={"number"}
            fullWidth={true}
            value={value}
            sx={FormTextFieldProps()}
            onChange={onChange}
            error={!!error}
            {...register("year")}
            helperText={error ? error.message : null}
          ></TextField>
        )}
      />
      <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <Button variant={"contained"} color={"secondary"} onClick={props.onClose}>Cancel</Button>
        <Button
          variant={"contained"}
          color={"orange"}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </Box>
    </FormCard>);
}